import React, { useContext, useEffect, useState } from 'react';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useNavigate } from 'react-router-dom';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { Country } from '../../../../../types/enums';
import {
  LeagueResponse,
  LeagueSeasonStatsResponse,
  RoundResponse,
  MatchResponse,
} from '../../../../../types/apimodels';
import { FaArrowLeft } from 'react-icons/fa';

const AdminHandleMatchesPage: React.FC = () => {
  const { role } = useJwt();
  const navigate = useNavigate();
  const apiContext = useContext(ApiContext);

  const [filterCountry, setFilterCountry] = useState<string>('');
  const [leagues, setLeagues] = useState<LeagueResponse[] | null>(null);
  const [leagueId, setLeagueId] = useState<string>('');
  const [selectedLeague, setSelectedLeague] = useState<LeagueResponse | null>(null);
  const [leagueSeasonStatsId, setLeagueSeasonStatsId] = useState<string>('');
  const [selectedRoundId, setSelectedRoundId] = useState<string>('');
  const [matches, setMatches] = useState<MatchResponse[] | null>(null);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { getLeagueByCountryAsync } = apiContext;

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  // Fetch leagues when country changes
  useEffect(() => {
    const fetchLeagues = async () => {
      if (filterCountry) {
        const countryValue = parseInt(filterCountry, 10) as Country;
        const response = await getLeagueByCountryAsync(countryValue);
        setLeagues(response);
        setLeagueId('');
        setLeagueSeasonStatsId('');
        setSelectedLeague(null);
        setMatches(null);
      } else {
        setLeagues(null);
        setLeagueId('');
        setLeagueSeasonStatsId('');
        setSelectedLeague(null);
        setMatches(null);
      }
    };
    void fetchLeagues();
  }, [filterCountry, getLeagueByCountryAsync]);

  // Update selectedLeague when leagueId changes
  useEffect(() => {
    if (leagues && leagueId) {
      const league = leagues.find(l => l.id === leagueId) || null;
      setSelectedLeague(league);
      setLeagueSeasonStatsId('');
      setMatches(null);
    } else {
      setSelectedLeague(null);
      setMatches(null);
    }
  }, [leagueId, leagues]);

  // Fetch rounds and matches when leagueSeasonStatsId or selectedRoundId changes
  useEffect(() => {
    if (selectedLeague && leagueSeasonStatsId) {
      const selectedStats = selectedLeague.leagueSeasonStats.find(s => s.id === leagueSeasonStatsId) || null;
      const selectedRound = selectedStats?.rounds.find(r => r.id === selectedRoundId) || null;
      setMatches(selectedRound?.matches || null);
    } else {
      setMatches(null);
    }
  }, [leagueSeasonStatsId, selectedRoundId, selectedLeague]);

  const handleNavigateToEdit = (match: MatchResponse) => {
    navigate(`/admin/matches/edit/${match.id}`, { state: { match } });
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <button className="text-primary hover:text-primary-dark mb-6" onClick={() => navigate(-1)}>
          <FaArrowLeft size={24} />
        </button>
        <h1 className="text-3xl font-bold text-primary text-center mb-6">Manage Matches</h1>

        {/* Filters */}
        <div className="flex flex-col sm:flex-row sm:space-x-4 mb-6">
          {/* Country Filter */}
          <div className="mb-4 sm:mb-0">
            <label className="block text-sm font-medium text-gray-700 mb-1">Country</label>
            <select
              className="border border-gray-300 rounded-md py-2 px-3 w-full"
              value={filterCountry}
              onChange={e => setFilterCountry(e.target.value)}>
              <option value="">Select Country</option>
              {Object.keys(Country)
                .filter(key => isNaN(Number(key)))
                .map(key => {
                  const value = Country[key as keyof typeof Country] as number;
                  return (
                    <option key={key} value={value.toString()}>
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </option>
                  );
                })}
            </select>
          </div>

          {/* League Filter */}
          {leagues && (
            <div className="mb-4 sm:mb-0">
              <label className="block text-sm font-medium text-gray-700 mb-1">League</label>
              <select
                className="border border-gray-300 rounded-md py-2 px-3 w-full"
                value={leagueId}
                onChange={e => setLeagueId(e.target.value)}>
                <option value="">Select League</option>
                {leagues.map(league => (
                  <option key={league.id} value={league.id}>
                    {league.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* League Season Stats Filter */}
          {selectedLeague && selectedLeague.leagueSeasonStats.length > 0 && (
            <div className="mb-4 sm:mb-0">
              <label className="block text-sm font-medium text-gray-700 mb-1">League Season Stats</label>
              <select
                className="border border-gray-300 rounded-md py-2 px-3 w-full"
                value={leagueSeasonStatsId}
                onChange={e => setLeagueSeasonStatsId(e.target.value)}>
                <option value="">Select Season</option>
                {selectedLeague.leagueSeasonStats.map((stats: LeagueSeasonStatsResponse) => (
                  <option key={stats.id} value={stats.id}>
                    Season: {stats.season}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Round Filter */}
          {selectedLeague && leagueSeasonStatsId && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Round</label>
              <select
                className="border border-gray-300 rounded-md py-2 px-3 w-full"
                value={selectedRoundId}
                onChange={e => setSelectedRoundId(e.target.value)}>
                <option value="">Select Round</option>
                {selectedLeague.leagueSeasonStats
                  .find(s => s.id === leagueSeasonStatsId)
                  ?.rounds.map((round: RoundResponse) => (
                    <option key={round.id} value={round.id}>
                      Round {round.roundNumber}
                    </option>
                  ))}
              </select>
            </div>
          )}
        </div>

        {/* Matches Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg">
            <thead>
              <tr>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Match</th>
              </tr>
            </thead>
            <tbody>
              {matches && matches.length > 0 ? (
                matches.map(match => (
                  <tr
                    key={match.id}
                    className="border-b hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleNavigateToEdit(match)}>
                    <td className="py-4 px-6 text-sm text-gray-700">
                      {match.homeTeamName} {match.homeTeamGoals} - {match.awayTeamGoals} {match.awayTeamName}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={1} className="py-4 px-6 text-center text-sm text-gray-500">
                    No matches found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminHandleMatchesPage;
