import React, { useContext, useEffect, useState, useRef } from 'react';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import { useNavigate } from 'react-router-dom';
import { useJwt } from '../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../Contexts/ApiProvider';
import SimpleTable from '../../../Partials/SimpleTable/SimpleTable';
import { UUID } from 'crypto';
import { SimpleTableResponse, UpdateSimpleTableRequest } from '../../../../types/apimodels';
import { toast } from 'react-toastify';
import { FaArrowLeft } from 'react-icons/fa6';

const MyAccountTablesPage: React.FC = () => {
  const clientUrl = import.meta.env.VITE_CLIENT_URL || window.location.origin;
  const apiContext = useContext(ApiContext);
  const { role, handlerId } = useJwt();
  const navigate = useNavigate();
  const [tableResponse, setTableResponse] = useState<SimpleTableResponse[] | null>(null);
  const [updateError, setUpdateError] = useState<{ tableId: UUID; message: string } | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const hasFetchedTables = useRef(false);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { getUsersTableAsync, deleteSimpleTableAsync, updateSimpleTableAsync } = apiContext;

  useEffect(() => {
    if (role === null) {
      navigate('/notauthorized');
    } else if (!hasFetchedTables.current) {
      fetchTables();
      hasFetchedTables.current = true;
    }
  }, [role, navigate]);

  const fetchTables = async () => {
    if (handlerId !== null) {
      try {
        const response = await getUsersTableAsync(handlerId);
        setTableResponse(response);
      } catch (error) {
        console.error('Failed to fetch tables:', error);
        toast.error('Failed to fetch tables. Please try again later.');
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const deleteTable = async (tableId: UUID) => {
    const response = await deleteSimpleTableAsync(tableId);
    if (response) {
      setTableResponse(prev => (prev ? prev.filter(table => table.id !== tableId) : null));
      toast.success('Table deleted successfully.');
    } else {
      toast.error('Failed to delete table. Please try again.');
    }
  };

  const updateTable = async (tableId: UUID, isPublicTable: boolean) => {
    const updateRequest: UpdateSimpleTableRequest = {
      tableId,
      isPublicTable,
    };
    const response = await updateSimpleTableAsync(updateRequest);
    if (!response) {
      setUpdateError({ tableId, message: 'Failed to update table visibility.' });
      toast.error('Failed to update table visibility. Please try again.');
    } else {
      setTableResponse(prev =>
        prev ? prev.map(table => (table.id === tableId ? { ...table, isPublicTable } : table)) : null
      );
      setUpdateError(null);
      toast.success('Table visibility updated.');
    }
  };

  const handleToggleChange = (tableId: UUID, currentState: boolean) => {
    updateTable(tableId, !currentState);
  };

  // Function to handle sharing the table URL
  const handleShare = async (tableId: UUID, isPublicTable: boolean) => {
    if (!isPublicTable) {
      toast.error("You can't share a private table");
      return;
    }
    const shareUrl = `${clientUrl}/simpletable/${tableId}`;
    try {
      await navigator.clipboard.writeText(shareUrl);
      toast.success('Table URL copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy text: ', err);
      toast.error('Failed to copy table URL. Please try again.');
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 sm:px-6 lg:px-8 py-8 pt-20">
        <div className="flex items-center mb-6 cursor-pointer" onClick={() => navigate(-1)}>
          <FaArrowLeft className="text-xl text-primary mr-2" />
          <span className="text-xl text-primary">Back</span>
        </div>
        <h1 className="text-2xl font-bold mb-6 text-center">My Tables</h1>
        {/* Button to create a new table */}
        <button
          onClick={() => navigate('/my-account/tables/simpletables/createnew')}
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded mb-6">
          Create New Table
        </button>

        {/* Conditional rendering based on loading and tableResponse */}
        {loading ? (
          // Spinner
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-primary"></div>
          </div>
        ) : tableResponse === null || tableResponse.length === 0 ? (
          <p className="text-gray-500">You have not created any tables yet.</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {tableResponse.map(table => (
              <div key={table.id} className="bg-white shadow rounded-lg p-4 relative">
                {/* Table Component */}
                <SimpleTable tableData={table} />

                {/* Public Table Toggle */}
                <div className="flex items-center mt-4">
                  <label htmlFor={`isPublicTable-${table.id}`} className="block text-sm font-medium text-gray-700 mr-4">
                    Public Table
                  </label>
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      id={`isPublicTable-${table.id}`}
                      checked={table.isPublicTable}
                      onChange={() => handleToggleChange(table.id, table.isPublicTable)}
                      className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:bg-blue-600">
                      <span
                        className={`absolute left-0 top-0 h-6 w-6 bg-white border border-gray-300 rounded-full transition-transform duration-200 transform ${
                          table.isPublicTable ? 'translate-x-full' : ''
                        }`}></span>
                    </div>
                  </label>
                </div>
                {/* Error Message */}
                {updateError && updateError.tableId === table.id && (
                  <p className="text-red-500 text-sm mt-2">{updateError.message}</p>
                )}
                {/* Share Button */}
                <button
                  onClick={() => handleShare(table.id, table.isPublicTable)}
                  aria-disabled={!table.isPublicTable}
                  className={`text-white font-semibold py-2 px-4 rounded mt-4 w-full ${
                    table.isPublicTable
                      ? 'bg-primary hover:bg-primary-light cursor-pointer'
                      : 'bg-gray-400 cursor-not-allowed'
                  }`}
                  title={table.isPublicTable ? 'Share this table with others' : 'You cannot share a private table'}>
                  Share Table
                </button>
                {/* Delete Button */}
                <button
                  onClick={() => deleteTable(table.id)}
                  className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded mt-2 w-full">
                  Delete Table
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
      <MainFooter />
    </div>
  );
};

export default MyAccountTablesPage;
