import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ApiContext } from '../../../../Contexts/ApiProvider';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';

const ResetPassword: React.FC = () => {
  const location = useLocation();
  const apiContext = useContext(ApiContext);
  const [email, setEmail] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { resetPasswordAsync } = apiContext;

  interface ResetPasswordRequest {
    email: string;
    token: string;
    newPassword: string;
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryEmail = queryParams.get('email');
    const queryToken = queryParams.get('token');

    setEmail(queryEmail);
    setToken(queryToken);
  }, [location.search]);

  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
        'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character'
      )
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      if (email != null && token != null) {
        const request: ResetPasswordRequest = { email, token, newPassword: values.newPassword };
        const result = await resetPasswordAsync(request);
        if (result) {
          navigate('/login');
        } else {
          setErrorMessage('The password could not be changed, please try again');
        }
      }
    },
  });

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 flex flex-col items-center justify-center p-6">
        <h1 className="text-4xl font-bold text-primary mb-6 text-center">Reset Password</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="w-full max-w-md bg-gray-100 p-6 border border-gray-300 rounded-lg shadow-lg">
          <div className="mb-4">
            <label htmlFor="newPassword" className="block text-lg font-medium text-gray-700">
              New Password
            </label>
            <input
              type="password"
              name="newPassword"
              className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.newPassword && formik.errors.newPassword ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.newPassword}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label htmlFor="confirmPassword" className="block text-lg font-medium text-gray-700">
              Confirm Password
            </label>
            <input
              type="password"
              name="confirmPassword"
              className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.confirmPassword}</div>
            ) : null}
          </div>
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 px-4 rounded-md font-semibold hover:bg-primary-dark transition-colors duration-300">
            Reset Password
          </button>
        </form>
        {errorMessage && <div className="mt-3 text-red-500 text-center">{errorMessage}</div>}
      </div>
      <MainFooter />
    </div>
  );
};

export default ResetPassword;
