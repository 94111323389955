import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { FaArrowLeft } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Country, Season } from '../../../../../types/enums';
import { UUID } from 'crypto';
import { LeagueResponse } from '../../../../../types/apimodels';

interface FormValues {
  country: string;
  leagueId: string;
  season: string;
}

const AdminRegisterLeagueSeasonStatsPage: React.FC = () => {
  const { role } = useJwt();
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();

  const [resultMessage, setResultMessage] = useState<string | null>(null);
  const [leagues, setLeagues] = useState<LeagueResponse[] | null>(null);

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { registerLssAsync, getLeagueByCountryAsync } = apiContext;

  // Validation schema
  const validationSchema = Yup.object({
    country: Yup.string().required('Required'),
    leagueId: Yup.string().required('Required'),
    season: Yup.string().required('Required'),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      country: '',
      leagueId: '',
      season: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const leagueId = values.leagueId as UUID;
      const season = parseInt(values.season, 10) as Season;

      const request = {
        leagueId,
        season,
      };

      const success = await registerLssAsync(request);
      if (success) {
        setResultMessage('League Season Stats registered successfully.');
        formik.resetForm();
        setLeagues(null);
      } else {
        setResultMessage('Failed to register League Season Stats.');
      }
    },
  });

  // Fetch leagues when country changes
  useEffect(() => {
    const fetchLeagues = async () => {
      if (formik.values.country) {
        const countryValue = parseInt(formik.values.country, 10) as Country;
        const response = await getLeagueByCountryAsync(countryValue);
        setLeagues(response);
        formik.setFieldValue('leagueId', '');
      } else {
        setLeagues(null);
        formik.setFieldValue('leagueId', '');
      }
    };
    void fetchLeagues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country]);

  // Prepare seasons
  const seasonKeys = Object.keys(Season).filter(key => isNaN(Number(key)));
  // seasonKeys will be like ["Season2023", "Season20232024", ...]
  // each key maps to Season[key], which is a number.

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <div className="flex items-center mb-6">
          <button className="text-primary hover:text-primary-dark" onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} />
          </button>
          <h1 className="text-3xl font-bold text-primary text-center flex-grow">Register League Season Stats</h1>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg mx-auto space-y-4">
          {/* Country */}
          <div>
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Country <span className="text-red-500">*</span>
            </label>
            <select
              name="country"
              className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="" label="Select country" />
              {Object.keys(Country)
                .filter(key => isNaN(Number(key)))
                .map(key => {
                  const value = Country[key as keyof typeof Country] as number;
                  return (
                    <option key={key} value={value.toString()}>
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </option>
                  );
                })}
            </select>
            {formik.touched.country && formik.errors.country && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.country}</div>
            )}
          </div>

          {/* League */}
          {formik.values.country &&
            (leagues === null ? (
              <p className="text-center text-gray-500">Loading leagues...</p>
            ) : leagues && leagues.length === 0 ? (
              <p className="text-center text-gray-500">No leagues found for this country.</p>
            ) : (
              leagues && (
                <div>
                  <label htmlFor="leagueId" className="block text-sm font-medium text-gray-700">
                    League <span className="text-red-500">*</span>
                  </label>
                  <select
                    name="leagueId"
                    className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                    value={formik.values.leagueId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}>
                    <option value="" label="Select league" />
                    {leagues.map(league => (
                      <option key={league.id} value={league.id}>
                        {league.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.leagueId && formik.errors.leagueId && (
                    <div className="text-red-500 text-sm mt-1">{formik.errors.leagueId}</div>
                  )}
                </div>
              )
            ))}

          {/* Season */}
          {formik.values.leagueId && (
            <div>
              <label htmlFor="season" className="block text-sm font-medium text-gray-700">
                Season <span className="text-red-500">*</span>
              </label>
              <select
                name="season"
                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={formik.values.season}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}>
                <option value="" label="Select season" />
                {seasonKeys.map(key => {
                  const value = Season[key as keyof typeof Season] as number;
                  return (
                    <option key={key} value={value.toString()}>
                      {key.replace(/([A-Z])/g, ' $1').trim()} {/* Adjust if needed */}
                    </option>
                  );
                })}
              </select>
              {formik.touched.season && formik.errors.season && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.season}</div>
              )}
            </div>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            disabled={!formik.values.season || !formik.values.leagueId || !formik.values.country}
            className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            Register League Season Stats
          </button>
        </form>

        {/* Result Message */}
        {resultMessage && <div className="mt-6 text-center text-lg font-semibold text-primary">{resultMessage}</div>}
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminRegisterLeagueSeasonStatsPage;
