import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { FaArrowLeft } from 'react-icons/fa';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import MainHeader from '../../../../Partials/Header/MainHeader';
import { UUID } from 'crypto';
import { TeamSeasonStatsResponse, UpdateTeamSeasonStatsRequest } from '../../../../../types/apimodels';

interface FormValues {
  goalsFor: number | null;
  goalsAgainst: number | null;
  points: number | null;
}

const AdminEditTeamSeasonStatsPage: React.FC = () => {
  const { role } = useJwt();
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { tssId } = useParams<{ tssId: UUID }>();

  const [teamSeasonStats, setTeamSeasonStats] = useState<TeamSeasonStatsResponse | null>(location.state?.tss || null);
  const [resultMessage, setResultMessage] = useState<string | null>(null);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { getTssByIdAsync, updateTssAsync } = apiContext;

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  useEffect(() => {
    const fetchTssIfNeeded = async () => {
      if (!teamSeasonStats && tssId) {
        const response = await getTssByIdAsync(tssId);
        setTeamSeasonStats(response);
      }
    };
    void fetchTssIfNeeded();
  }, [teamSeasonStats, tssId, getTssByIdAsync]);

  const validationSchema = Yup.object({
    goalsFor: Yup.number().nullable().min(0, 'Must be at least 0').required('Required'),
    goalsAgainst: Yup.number().nullable().min(0, 'Must be at least 0').required('Required'),
    points: Yup.number().nullable().min(0, 'Must be at least 0').required('Required'),
  });

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      goalsFor: teamSeasonStats?.goalsFor ?? null,
      goalsAgainst: teamSeasonStats?.goalsAgainst ?? null,
      points: teamSeasonStats?.points ?? null,
    },
    validationSchema,
    onSubmit: async values => {
      if (!tssId) return;

      const request: UpdateTeamSeasonStatsRequest = {
        id: tssId,
        goalsFor: values.goalsFor,
        goalsAgainst: values.goalsAgainst,
        points: values.points,
      };

      const response = await updateTssAsync(request);
      if (response) {
        setResultMessage('Team Season Stats updated successfully');
        // Optionally navigate back or refresh the page
        // navigate(`/admin/teams/stats/detail/${tssId}`);
      } else {
        setResultMessage('Failed to update Team Season Stats');
      }
    },
  });

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <div className="flex items-center mb-6">
          <button className="text-primary hover:text-primary-dark" onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} />
          </button>
          <h1 className="text-3xl font-bold text-primary text-center flex-grow">Edit Team Season Stats</h1>
        </div>
        {teamSeasonStats ? (
          <form onSubmit={formik.handleSubmit} className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg mx-auto">
            {/* Goals For */}
            <div className="mb-4">
              <label htmlFor="goalsFor" className="block text-sm font-medium text-gray-700">
                Goals For <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="goalsFor"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={formik.values.goalsFor ?? ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.goalsFor && formik.errors.goalsFor && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.goalsFor}</div>
              )}
            </div>

            {/* Goals Against */}
            <div className="mb-4">
              <label htmlFor="goalsAgainst" className="block text-sm font-medium text-gray-700">
                Goals Against <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="goalsAgainst"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={formik.values.goalsAgainst ?? ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.goalsAgainst && formik.errors.goalsAgainst && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.goalsAgainst}</div>
              )}
            </div>

            {/* Points */}
            <div className="mb-4">
              <label htmlFor="points" className="block text-sm font-medium text-gray-700">
                Points <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="points"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={formik.values.points ?? ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.points && formik.errors.points && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.points}</div>
              )}
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
              Update Team Season Stats
            </button>
          </form>
        ) : (
          <div className="text-center text-gray-500">Loading Team Season Stats...</div>
        )}
        {resultMessage && <div className="mt-6 text-center text-lg font-semibold text-primary">{resultMessage}</div>}
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminEditTeamSeasonStatsPage;
