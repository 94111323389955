import React, { useContext, useEffect, useState } from 'react';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useNavigate } from 'react-router-dom';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { Country } from '../../../../../types/enums';
import { LeagueResponse, LeagueSeasonStatsResponse, RoundResponse } from '../../../../../types/apimodels';
import { UUID } from 'crypto';
import { FaArrowLeft, FaEdit, FaTrash } from 'react-icons/fa';

type SortField = 'roundNumber' | 'deadline';

const AdminHandleRoundsPage: React.FC = () => {
  const { role } = useJwt();
  const navigate = useNavigate();
  const apiContext = useContext(ApiContext);

  const [filterCountry, setFilterCountry] = useState<string>('');
  const [leagues, setLeagues] = useState<LeagueResponse[] | null>(null);
  const [leagueId, setLeagueId] = useState<string>('');
  const [selectedLeague, setSelectedLeague] = useState<LeagueResponse | null>(null);
  const [leagueSeasonStatsId, setLeagueSeasonStatsId] = useState<string>('');
  const [rounds, setRounds] = useState<RoundResponse[] | null>(null);
  const [filteredRounds, setFilteredRounds] = useState<RoundResponse[] | null>(null);

  const [sortField, setSortField] = useState<SortField>('roundNumber');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { getLeagueByCountryAsync, deleteRoundAsync } = apiContext;

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  // Fetch leagues when country changes
  useEffect(() => {
    const fetchLeagues = async () => {
      if (filterCountry) {
        const countryValue = parseInt(filterCountry, 10) as Country;
        const response = await getLeagueByCountryAsync(countryValue);
        setLeagues(response);
        setLeagueId('');
        setLeagueSeasonStatsId('');
        setSelectedLeague(null);
        setRounds(null);
        setFilteredRounds(null);
      } else {
        setLeagues(null);
        setLeagueId('');
        setLeagueSeasonStatsId('');
        setSelectedLeague(null);
        setRounds(null);
        setFilteredRounds(null);
      }
    };
    void fetchLeagues();
  }, [filterCountry, getLeagueByCountryAsync]);

  // Update selectedLeague when leagueId changes
  useEffect(() => {
    if (leagues && leagueId) {
      const league = leagues.find(l => l.id === leagueId) || null;
      setSelectedLeague(league);
      setLeagueSeasonStatsId('');
      setRounds(null);
      setFilteredRounds(null);
    } else {
      setSelectedLeague(null);
      setRounds(null);
      setFilteredRounds(null);
    }
  }, [leagueId, leagues]);

  // Update rounds when leagueSeasonStatsId changes
  useEffect(() => {
    if (selectedLeague && leagueSeasonStatsId) {
      const selectedStats = selectedLeague.leagueSeasonStats.find(s => s.id === leagueSeasonStatsId) || null;
      const retrievedRounds = selectedStats ? selectedStats.rounds : null;
      setRounds(retrievedRounds);
      setFilteredRounds(retrievedRounds);
    } else {
      setRounds(null);
      setFilteredRounds(null);
    }
  }, [leagueSeasonStatsId, selectedLeague]);

  const handleNavigate = (path: string, state?: any) => {
    navigate(path, { state });
  };

  const handleDeleteRound = async (roundId: UUID) => {
    const confirmDelete = window.confirm('Do you really want to delete this round?');
    if (confirmDelete && rounds && filteredRounds) {
      const success = await deleteRoundAsync(roundId);
      if (success) {
        const updated = filteredRounds.filter(r => r.id !== roundId);
        setRounds(updated);
        setFilteredRounds(updated);
      } else {
        alert('Failed to delete round');
      }
    }
  };

  const handleSortChange = (sortValue: string) => {
    if (!sortValue || !filteredRounds) return;

    const [field, direction] = sortValue.split(':') as [SortField, 'asc' | 'desc'];
    setSortField(field);
    setSortDirection(direction);

    const sorted = [...filteredRounds].sort((a, b) => {
      const aField = a[field];
      const bField = b[field];

      if (aField == null || bField == null) return 0;

      if (aField < bField) return direction === 'asc' ? -1 : 1;
      if (aField > bField) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredRounds(sorted);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <button className="text-primary hover:text-primary-dark mb-6" onClick={() => navigate(-1)}>
          <FaArrowLeft size={24} />
        </button>
        <h1 className="text-3xl font-bold text-primary text-center mb-6">Manage Rounds</h1>

        {/* Filters */}
        <div className="flex flex-col sm:flex-row sm:space-x-4 mb-6">
          {/* Country Filter */}
          <div className="mb-4 sm:mb-0">
            <label className="block text-sm font-medium text-gray-700 mb-1">Country</label>
            <select
              className="border border-gray-300 rounded-md py-2 px-3 w-full"
              value={filterCountry}
              onChange={e => setFilterCountry(e.target.value)}>
              <option value="">All Countries</option>
              {Object.keys(Country)
                .filter(key => isNaN(Number(key)))
                .map(key => {
                  const value = Country[key as keyof typeof Country] as number;
                  return (
                    <option key={key} value={value.toString()}>
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </option>
                  );
                })}
            </select>
          </div>

          {/* League Filter */}
          {leagues && (
            <div className="mb-4 sm:mb-0">
              <label className="block text-sm font-medium text-gray-700 mb-1">League</label>
              <select
                className="border border-gray-300 rounded-md py-2 px-3 w-full"
                value={leagueId}
                onChange={e => setLeagueId(e.target.value)}>
                <option value="">All Leagues</option>
                {leagues.map(league => (
                  <option key={league.id} value={league.id}>
                    {league.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* LeagueSeasonStats Filter */}
          {selectedLeague && selectedLeague.leagueSeasonStats.length > 0 && (
            <div className="mb-4 sm:mb-0">
              <label className="block text-sm font-medium text-gray-700 mb-1">League Season Stats</label>
              <select
                className="border border-gray-300 rounded-md py-2 px-3 w-full"
                value={leagueSeasonStatsId}
                onChange={e => setLeagueSeasonStatsId(e.target.value)}>
                <option value="">All Seasons</option>
                {selectedLeague.leagueSeasonStats.map((stats: LeagueSeasonStatsResponse) => (
                  <option key={stats.id} value={stats.id}>
                    Season: {stats.season} {/* Adjust display if season is object */}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Sort Options */}
          {filteredRounds && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Sort By</label>
              <select
                className="border border-gray-300 rounded-md py-2 px-3 w-full"
                value={`${sortField}:${sortDirection}`}
                onChange={e => handleSortChange(e.target.value)}>
                <option value="roundNumber:asc">Round Number (Asc)</option>
                <option value="roundNumber:desc">Round Number (Desc)</option>
                <option value="deadline:asc">Deadline (Oldest first)</option>
                <option value="deadline:desc">Deadline (Latest first)</option>
              </select>
            </div>
          )}
        </div>

        {/* Rounds Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg">
            <thead>
              <tr>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Round Number</th>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Deadline</th>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredRounds && filteredRounds.length > 0 ? (
                filteredRounds.map(round => (
                  <tr key={round.id} className="border-b hover:bg-gray-100">
                    <td className="py-4 px-6 text-sm text-gray-700">{round.roundNumber}</td>
                    <td className="py-4 px-6 text-sm text-gray-700">{new Date(round.deadline).toLocaleString()}</td>
                    <td className="py-4 px-6 text-sm text-gray-700">
                      <div className="flex space-x-2">
                        <button
                          className="flex items-center text-white bg-blue-500 hover:bg-blue-600 px-2 py-1 rounded-md"
                          onClick={e => {
                            e.stopPropagation();
                            handleNavigate(`/admin/rounds/edit/${round.id}`, { state: { round } });
                          }}>
                          <FaEdit className="mr-1" />
                          Edit
                        </button>
                        <button
                          className="flex items-center text-white bg-red-500 hover:bg-red-600 px-2 py-1 rounded-md"
                          onClick={e => {
                            e.stopPropagation();
                            void handleDeleteRound(round.id);
                          }}>
                          <FaTrash className="mr-1" />
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="py-4 px-6 text-center text-sm text-gray-500">
                    No rounds found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminHandleRoundsPage;
