import React, { useContext, useEffect, useState } from 'react';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useNavigate } from 'react-router-dom';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { Country } from '../../../../../types/enums';
import { TeamResponse, TeamSeasonStatsResponse } from '../../../../../types/apimodels';
import { FaArrowLeft, FaEdit, FaTrash } from 'react-icons/fa';
import { UUID } from 'crypto';

const AdminHandleTeamSeasonStatsPage: React.FC = () => {
  const { role } = useJwt();
  const navigate = useNavigate();
  const apiContext = useContext(ApiContext);

  const [filterCountry, setFilterCountry] = useState<string>('');
  const [teams, setTeams] = useState<TeamResponse[] | null>(null);
  const [selectedTeamId, setSelectedTeamId] = useState<string>('');
  const [teamSeasonStats, setTeamSeasonStats] = useState<TeamSeasonStatsResponse[] | null>(null);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { getTeamByCountryAsync, deleteTssAsync } = apiContext;

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  // Fetch teams when country changes
  useEffect(() => {
    const fetchTeams = async () => {
      if (filterCountry) {
        const countryValue = parseInt(filterCountry, 10) as Country;
        const response = await getTeamByCountryAsync(countryValue);
        setTeams(response);
        setSelectedTeamId('');
        setTeamSeasonStats(null);
      } else {
        setTeams(null);
        setSelectedTeamId('');
        setTeamSeasonStats(null);
      }
    };
    void fetchTeams();
  }, [filterCountry, getTeamByCountryAsync]);

  // Update teamSeasonStats when selectedTeamId changes
  useEffect(() => {
    if (teams && selectedTeamId) {
      const selectedTeam = teams.find(t => t.id === selectedTeamId);
      setTeamSeasonStats(selectedTeam?.seasonStats || null);
    } else {
      setTeamSeasonStats(null);
    }
  }, [selectedTeamId, teams]);

  const handleNavigate = (path: string, state?: any) => {
    navigate(path, { state });
  };

  const handleDeleteTss = async (tssId: UUID) => {
    const confirmDelete = window.confirm('Do you really want to delete this Team Season Stats?');
    if (confirmDelete && teamSeasonStats) {
      const success = await deleteTssAsync(tssId);
      if (success) {
        const updatedStats = teamSeasonStats.filter(tss => tss.id !== tssId);
        setTeamSeasonStats(updatedStats);
      } else {
        alert('Failed to delete Team Season Stats');
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <button className="text-primary hover:text-primary-dark mb-6" onClick={() => navigate(-1)}>
          <FaArrowLeft size={24} />
        </button>
        <h1 className="text-3xl font-bold text-primary text-center mb-6">Manage Team Season Stats</h1>

        {/* Filters */}
        <div className="flex flex-col sm:flex-row sm:space-x-4 mb-6">
          {/* Country Filter */}
          <div className="mb-4 sm:mb-0">
            <label className="block text-sm font-medium text-gray-700 mb-1">Country</label>
            <select
              className="border border-gray-300 rounded-md py-2 px-3 w-full"
              value={filterCountry}
              onChange={e => setFilterCountry(e.target.value)}>
              <option value="">Select Country</option>
              {Object.keys(Country)
                .filter(key => isNaN(Number(key)))
                .map(key => {
                  const value = Country[key as keyof typeof Country] as number;
                  return (
                    <option key={key} value={value.toString()}>
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </option>
                  );
                })}
            </select>
          </div>

          {/* Team Filter */}
          {teams && (
            <div className="mb-4 sm:mb-0">
              <label className="block text-sm font-medium text-gray-700 mb-1">Team</label>
              <select
                className="border border-gray-300 rounded-md py-2 px-3 w-full"
                value={selectedTeamId}
                onChange={e => setSelectedTeamId(e.target.value)}>
                <option value="">Select Team</option>
                {teams.map(team => (
                  <option key={team.id} value={team.id}>
                    {team.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        {/* Team Season Stats Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg">
            <thead>
              <tr>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Season</th>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Goals For</th>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Goals Against</th>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Points</th>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              {teamSeasonStats && teamSeasonStats.length > 0 ? (
                teamSeasonStats.map(tss => (
                  <tr key={tss.id} className="border-b hover:bg-gray-100">
                    <td className="py-4 px-6 text-sm text-gray-700">{tss.season}</td>
                    <td className="py-4 px-6 text-sm text-gray-700">{tss.goalsFor}</td>
                    <td className="py-4 px-6 text-sm text-gray-700">{tss.goalsAgainst}</td>
                    <td className="py-4 px-6 text-sm text-gray-700">{tss.points}</td>
                    <td className="py-4 px-6 text-sm text-gray-700">
                      <div className="flex space-x-2">
                        <button
                          className="flex items-center text-white bg-blue-500 hover:bg-blue-600 px-2 py-1 rounded-md"
                          onClick={() => handleNavigate(`/admin/tss/edit/${tss.id}`, { state: { tss } })}>
                          <FaEdit className="mr-1" />
                          Edit
                        </button>
                        <button
                          className="flex items-center text-white bg-red-500 hover:bg-red-600 px-2 py-1 rounded-md"
                          onClick={() => void handleDeleteTss(tss.id)}>
                          <FaTrash className="mr-1" />
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="py-4 px-6 text-center text-sm text-gray-500">
                    No team season stats found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminHandleTeamSeasonStatsPage;
