import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { TeamResponse } from '../../../../../types/apimodels';
import { Country } from '../../../../../types/enums';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { UUID } from 'crypto';
import { FaArrowLeft } from 'react-icons/fa6';

type SortField = 'id' | 'name' | 'englishName' | 'country';

const AdminHandleTeamsPage: React.FC = () => {
  const { role } = useJwt();
  const navigate = useNavigate();
  const [teams, setTeams] = useState<TeamResponse[]>([]);
  const [filteredTeams, setFilteredTeams] = useState<TeamResponse[]>([]);
  const [filterCountry, setFilterCountry] = useState<string>('');
  const [sortField, setSortField] = useState<SortField>('id');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const apiContext = useContext(ApiContext);
  const hasFetchedTeams = useRef(false);

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    } else {
      fetchTeams();
    }
  }, [role, navigate]);

  const fetchTeams = async () => {
    if (apiContext?.getAllTeamsAsync && !hasFetchedTeams.current) {
      hasFetchedTeams.current = true;
      const response = await apiContext.getAllTeamsAsync();
      if (response) {
        setTeams(response);
        setFilteredTeams(response);
      }
    }
  };

  const handleDeleteTeam = async (teamId: UUID) => {
    const confirmDelete = window.confirm('Do you really want to delete this team?');
    if (confirmDelete && apiContext?.deleteTeamAsync) {
      const success = await apiContext.deleteTeamAsync(teamId);
      if (success) {
        setTeams(teams.filter(team => team.id !== teamId));
        setFilteredTeams(filteredTeams.filter(team => team.id !== teamId));
      } else {
        alert('Failed to delete team');
      }
    }
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleFilterChange = () => {
    let filtered = teams;

    if (filterCountry !== '') {
      filtered = filtered.filter(team => team.country.toString() === filterCountry);
    }

    setFilteredTeams(filtered);
  };

  const handleSortChange = (sortValue: string) => {
    if (!sortValue) return;

    const [field, direction] = sortValue.split(':') as [SortField, 'asc' | 'desc'];
    setSortField(field);
    setSortDirection(direction);

    const sorted = [...filteredTeams].sort((a, b) => {
      const aField = a[field];
      const bField = b[field];

      if (aField == null || bField == null) {
        return 0; // Handle null values
      }

      if (aField < bField) return direction === 'asc' ? -1 : 1;
      if (aField > bField) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredTeams(sorted);
  };

  useEffect(() => {
    handleFilterChange();
  }, [filterCountry, teams]);

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <button className="text-primary hover:text-primary-dark mb-4 self-start" onClick={() => navigate(-1)}>
          <FaArrowLeft size={24} />
        </button>
        <h1 className="text-3xl font-bold text-primary text-center mb-6">Manage Teams</h1>
        <div className="flex justify-end mb-6">
          {/* Filter and Sort Controls */}
          <div className="flex space-x-4">
            {/* Filter by Country */}
            <select
              className="border border-gray-300 rounded-md py-2 px-3"
              value={filterCountry}
              onChange={e => setFilterCountry(e.target.value)}>
              <option value="">All Countries</option>
              {Object.keys(Country)
                .filter(key => isNaN(Number(key)))
                .map(key => {
                  const value = Country[key as keyof typeof Country] as number;
                  return (
                    <option key={value} value={value.toString()}>
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </option>
                  );
                })}
            </select>
            {/* Sort Options */}
            <select
              className="border border-gray-300 rounded-md py-2 px-3"
              value={`${sortField}:${sortDirection}`}
              onChange={e => handleSortChange(e.target.value)}>
              <option value="">Sort By</option>
              <option value="name:asc">Name (A-Z)</option>
              <option value="name:desc">Name (Z-A)</option>
              <option value="country:asc">Country (A-Z)</option>
              <option value="country:desc">Country (Z-A)</option>
            </select>
          </div>
        </div>
        {/* Teams List for Mobile */}
        <div className="grid grid-cols-1 gap-4 sm:hidden">
          {filteredTeams.map(team => (
            <div key={team.id} className="bg-white p-4 rounded-lg shadow">
              <div
                key={team.id}
                className="bg-white p-4 rounded-lg shadow cursor-pointer"
                onClick={() => handleNavigate(`/admin/teams/detail/${team.id}`)}>
                <div>
                  <p className="text-lg font-medium text-gray-900">{team.name}</p>
                  <p className="text-sm text-gray-500">{Country[team.country].replace(/([A-Z])/g, ' $1').trim()}</p>
                </div>
                <div className="flex space-x-2">
                  <button
                    className="flex items-center text-white bg-blue-500 hover:bg-blue-600 px-2 py-1 rounded-md"
                    onClick={() => handleNavigate(`/admin/teams/edit/${team.id}`)}>
                    <FaEdit />
                  </button>
                  <button
                    className="flex items-center text-white bg-red-500 hover:bg-red-600 px-2 py-1 rounded-md"
                    onClick={() => handleDeleteTeam(team.id)}>
                    <FaTrash />
                  </button>
                </div>
              </div>
              {team.englishName && <p className="text-sm text-gray-700 mt-2">English Name: {team.englishName}</p>}
            </div>
          ))}
          {filteredTeams.length === 0 && <div className="text-center text-sm text-gray-500">No teams found.</div>}
        </div>
        {/* Teams Table for Desktop */}
        <div className="overflow-x-auto hidden sm:block">
          <table className="min-w-full bg-white shadow-md rounded-lg">
            <thead>
              <tr>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Name</th>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700 hidden md:table-cell">
                  English Name
                </th>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Country</th>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredTeams.map(team => (
                <tr
                  key={team.id}
                  className="border-b cursor-pointer hover:bg-gray-100"
                  onClick={() => handleNavigate(`/admin/teams/detail/${team.id}`)}>
                  <td className="py-4 px-6 text-sm text-gray-700">{team.name}</td>
                  <td className="py-4 px-6 text-sm text-gray-700 hidden md:table-cell">{team.englishName || 'N/A'}</td>
                  <td className="py-4 px-6 text-sm text-gray-700">
                    {Country[team.country].replace(/([A-Z])/g, ' $1').trim()}
                  </td>
                  <td className="py-4 px-6 text-sm text-gray-700">
                    <div className="flex space-x-2" onClick={e => e.stopPropagation()}>
                      <button
                        className="flex items-center text-white bg-blue-500 hover:bg-blue-600 px-2 py-1 rounded-md"
                        onClick={() => handleNavigate(`/admin/teams/edit/${team.id}`)}>
                        <FaEdit className="mr-1" />
                        Edit
                      </button>
                      <button
                        className="flex items-center text-white bg-red-500 hover:bg-red-600 px-2 py-1 rounded-md"
                        onClick={() => handleDeleteTeam(team.id)}>
                        <FaTrash className="mr-1" />
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
              {filteredTeams.length === 0 && (
                <tr>
                  <td colSpan={4} className="py-4 px-6 text-center text-sm text-gray-500">
                    No teams found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminHandleTeamsPage;
