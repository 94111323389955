import React, { useContext, useEffect, useState, useRef } from 'react';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiContext } from '../../../../Contexts/ApiProvider';
import SimpleTable from '../../../Partials/SimpleTable/SimpleTable';
import { PublicSimpleTableResponse } from '../../../../types/apimodels';
import StarRating from '../../../Partials/SimpleTable/StarRating';
import { UUID } from 'crypto';
import { FaUser, FaCalendarAlt, FaEdit, FaEye, FaStar } from 'react-icons/fa';

const PublicSimpleTablePage: React.FC = () => {
  const apiContext = useContext(ApiContext);
  const { tableId } = useParams<{ tableId: UUID }>();
  const [tableResponse, setTableResponse] = useState<PublicSimpleTableResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const effectRan = useRef(false);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { getPublicTableAsync, updateSimpleTableViewsAsync } = apiContext;

  useEffect(() => {
    if (effectRan.current === false) {
      effectRan.current = true;

      if (tableId) {
        const fetchData = async () => {
          try {
            const response = await getPublicTableAsync(tableId);
            if (response) {
              setTableResponse(response);
            } else {
              setTableResponse(null);
            }
          } catch (error) {
            console.error('Failed to fetch table:', error);
            setTableResponse(null);
          } finally {
            setLoading(false);
          }
        };

        fetchData();

        updateSimpleTableViewsAsync(tableId).catch(error => {
          console.error('Failed to update table views:', error);
        });
      } else {
        setLoading(false);
      }
    }
  }, [tableId, getPublicTableAsync, updateSimpleTableViewsAsync]);

  const formatDate = (dateString: string | Date): string => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat(undefined, {
      dateStyle: 'medium',
      timeStyle: 'short',
    }).format(date);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 sm:px-6 lg:px-8 py-8 pt-16">
        {loading ? (
          // Spinner
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-primary"></div>
          </div>
        ) : tableResponse ? (
          <div className="max-w-4xl mx-auto bg-white shadow rounded-lg p-6">
            {/* Table Component */}
            <SimpleTable tableData={tableResponse} />

            {/* Metadata Section */}
            <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6">
              {/* Created by */}
              <div
                className={`flex items-center ${
                  tableResponse?.userName && !tableResponse?.userName.includes('@tabletoptippers.com')
                    ? 'cursor-pointer'
                    : ''
                }`}
                onClick={() => {
                  if (tableResponse?.userName && !tableResponse.userName.includes('@tabletoptippers.com')) {
                    navigate(`/userprofile/${tableResponse?.userName}`);
                  }
                }}>
                <FaUser className="text-primary mr-2" />
                <span className="text-gray-700">
                  {tableResponse?.userName
                    ? tableResponse?.userName.includes('@tabletoptippers.com')
                      ? 'Table Top Tippers'
                      : tableResponse?.userName
                    : 'Anonymous'}
                </span>
              </div>
              {/* Created Date */}
              <div className="flex items-center">
                <FaCalendarAlt className="text-primary mr-2" />
                <span className="text-gray-700">Created: {formatDate(tableResponse?.created)}</span>
              </div>
              {/* Last Modified Date */}
              <div className="flex items-center">
                <FaEdit className="text-primary mr-2" />
                <span className="text-gray-700">
                  Last Modified: {tableResponse?.lastModified ? formatDate(tableResponse?.lastModified) : 'Never'}
                </span>
              </div>
              {/* Rating */}
              <div className="flex items-center">
                <FaStar className="text-primary mr-2" />
                <StarRating rating={tableResponse.rating} />
                <span className="ml-2 text-gray-700">({tableResponse?.rating.toFixed(1)})</span>
              </div>
              {/* Views */}
              <div className="flex items-center">
                <FaEye className="text-primary mr-2" />
                <span className="text-gray-700">Views: {tableResponse?.views}</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-gray-500 mb-4">Table not found or is not public.</p>
            <button
              onClick={() => navigate('/')}
              className="bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark transition-colors duration-300">
              Go to Home
            </button>
          </div>
        )}
      </div>
      <MainFooter />
    </div>
  );
};

export default PublicSimpleTablePage;
