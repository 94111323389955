import React, { useEffect } from 'react';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useNavigate } from 'react-router-dom';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { FaArrowLeft } from 'react-icons/fa6';
import { FaClipboardList, FaPlusCircle } from 'react-icons/fa';

const AdminTeamSeasonStatsPage: React.FC = () => {
  const { role } = useJwt();
  const navigate = useNavigate();

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <button className="text-primary hover:text-primary-dark mb-4 self-start" onClick={() => navigate(-1)}>
          <FaArrowLeft size={24} />
        </button>
        <h1 className="text-3xl font-bold text-primary text-center mb-6">Team season stats options</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div
            className="cursor-pointer bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition duration-300"
            onClick={() => handleNavigate('/admin/tss/register')}>
            <div className="flex items-center">
              <FaPlusCircle className="h-12 w-12 text-primary" />
              <h2 className="ml-4 text-xl font-semibold text-gray-700">Create team season stats</h2>
            </div>
            <p className="mt-2 text-gray-500">Create a new team season stats.</p>
          </div>
          {/* Manage Teams Card */}
          <div
            className="cursor-pointer bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition duration-300"
            onClick={() => handleNavigate('/admin/tss/handle')}>
            <div className="flex items-center">
              <FaClipboardList className="h-12 w-12 text-primary" />
              <h2 className="ml-4 text-xl font-semibold text-gray-700">Manage team season stats</h2>
            </div>
            <p className="mt-2 text-gray-500">View and manage existing team season stats.</p>
          </div>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminTeamSeasonStatsPage;
