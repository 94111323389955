import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import defaultProfilePicture from '../../../../Assets/Images/DefaultPP.webp';
import { AdminGetDetailedUserResponse } from '../../../../../types/apimodels';
import { FaArrowLeft } from 'react-icons/fa';

const AdminDetailedUserPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const apiContext = useContext(ApiContext);
  const { role } = useJwt();
  const navigate = useNavigate();
  const [user, setUser] = useState<AdminGetDetailedUserResponse | null>(null);
  const hasFetchedUser = useRef(false);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { adminGetUserAsync, adminDeleteUserAsync } = apiContext;

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    } else {
      fetchUser();
    }
  }, [role, navigate, userId]);

  const fetchUser = async () => {
    if (userId && !hasFetchedUser.current) {
      hasFetchedUser.current = true;
      const response = await adminGetUserAsync(userId);
      setUser(response);
    }
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleDeleteUser = async (userId: string) => {
    const confirmDelete = window.confirm('Do you really want to delete this user?');
    if (confirmDelete) {
      const success = await adminDeleteUserAsync(userId);
      if (success) {
        navigate('/admin/users/handle');
      } else {
        alert('Failed to delete user');
      }
    }
  };

  const formatDateTime = (dateTime: string | null): string => {
    return dateTime ? new Date(dateTime).toLocaleString() : 'Never';
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <button className="text-primary hover:text-primary-dark mb-4 self-start" onClick={() => navigate(-1)}>
          <FaArrowLeft size={24} />
        </button>
        <div className="flex flex-col items-center">
          <div className="profile-image mb-6">
            <img
              src={user?.imageUrl || defaultProfilePicture}
              alt="Profile"
              className="w-36 h-36 rounded-full object-cover"
            />
          </div>
          <div className="user-info bg-white shadow-md rounded-lg p-6 w-full max-w-xl">
            <h1 className="text-2xl font-semibold text-center mb-4">{user?.name || 'null'}</h1>
            <div className="text-left space-y-2">
              <p>
                <strong>First Name:</strong> {user?.firstName || 'null'}
              </p>
              <p>
                <strong>Last Name:</strong> {user?.lastName || 'null'}
              </p>
              <p>
                <strong>Email:</strong> {user?.userName || 'null'}
              </p>
              <p>
                <strong>Role:</strong> {user?.role || 'null'}
              </p>
              <p>
                <strong>Phone Number:</strong> {user?.phoneNumber || 'null'}
              </p>
              <p>
                <strong>Email Confirmed:</strong> {user?.emailConfirmed ? 'Yes' : 'No'}
              </p>
              <p>
                <strong>Phone Confirmed:</strong> {user?.phoneConfirmed ? 'Yes' : 'No'}
              </p>
              <p>
                <strong>Created:</strong> {formatDateTime(user?.created ?? null)}
              </p>
              <p>
                <strong>Last Login:</strong> {formatDateTime(user?.lastLogin ?? null)}
              </p>
            </div>
            <div className="flex justify-center mt-6 space-x-4">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                onClick={e => {
                  e.stopPropagation();
                  handleDeleteUser(user?.id!);
                }}>
                Delete
              </button>
              <button
                className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600"
                onClick={e => {
                  e.stopPropagation();
                  handleNavigate(`/admin/users/update/${user?.id}`);
                }}>
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminDetailedUserPage;
