import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { FaArrowLeft } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Country } from '../../../../../types/enums';
import { UUID } from 'crypto';
import {
  LeagueResponse,
  LeagueSeasonStatsResponse,
  RoundResponse,
  TeamSeasonStatsResponse,
} from '../../../../../types/apimodels';

interface FormValues {
  country: string;
  leagueId: string;
  leagueSeasonStatsId: string;
  roundId: string;
  homeTeamStatsId: string;
  awayTeamStatsId: string;
  homeTeamGoals: number;
  awayTeamGoals: number;
}

const AdminRegisterMatchPage: React.FC = () => {
  const { role } = useJwt();
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();

  const [resultMessage, setResultMessage] = useState<string | null>(null);
  const [leagues, setLeagues] = useState<LeagueResponse[] | null>(null);
  const [selectedLeague, setSelectedLeague] = useState<LeagueResponse | null>(null);
  const [leagueSeasonStats, setLeagueSeasonStats] = useState<LeagueSeasonStatsResponse | null>(null);
  const [rounds, setRounds] = useState<RoundResponse[] | null>(null);
  const [teams, setTeams] = useState<TeamSeasonStatsResponse[] | null>(null);
  const [excludedTeamIds, setExcludedTeamIds] = useState<Set<UUID>>(new Set());

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { registerMatchAsync, getLeagueByCountryAsync } = apiContext;

  const validationSchema = Yup.object({
    country: Yup.string().required('Required'),
    leagueId: Yup.string().required('Required'),
    leagueSeasonStatsId: Yup.string().required('Required'),
    roundId: Yup.string().required('Required'),
    homeTeamStatsId: Yup.string()
      .required('Required')
      .test('different-teams', 'Home and Away team cannot be the same', function (value) {
        return value !== this.parent.awayTeamStatsId;
      }),
    awayTeamStatsId: Yup.string().required('Required'),
    homeTeamGoals: Yup.number().min(0, 'Must be at least 0').max(200, 'Cannot exceed 200').required('Required'),
    awayTeamGoals: Yup.number().min(0, 'Must be at least 0').max(200, 'Cannot exceed 200').required('Required'),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      country: '',
      leagueId: '',
      leagueSeasonStatsId: '',
      roundId: '',
      homeTeamStatsId: '',
      awayTeamStatsId: '',
      homeTeamGoals: 0,
      awayTeamGoals: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const request = {
        homeTeamStatsId: values.homeTeamStatsId as UUID,
        awayTeamStatsId: values.awayTeamStatsId as UUID,
        homeTeamGoals: values.homeTeamGoals,
        awayTeamGoals: values.awayTeamGoals,
        roundId: values.roundId as UUID,
      };

      const success = await registerMatchAsync(request);
      if (success) {
        setResultMessage('Match registration was successful');
        formik.resetForm();
        setTeams(null);
        setRounds(null);
        setLeagueSeasonStats(null);
        setSelectedLeague(null);
        setLeagues(null);
        setExcludedTeamIds(new Set());
      } else {
        setResultMessage('Match registration failed');
      }
    },
  });

  const { values, handleChange, handleBlur, touched, errors, setFieldValue } = formik;

  useEffect(() => {
    if (leagues && values.leagueId) {
      const league = leagues.find(l => l.id === values.leagueId);
      if (league) {
        setSelectedLeague(league);
        console.log('League selected:', league); // Debugging
        void setFieldValue('leagueSeasonStatsId', '');
        void setFieldValue('roundId', '');
        setLeagueSeasonStats(null);
        setRounds(null);
        setTeams(null);
      } else {
        console.warn('No league found with the selected leagueId'); // Debugging
      }
    } else {
      setSelectedLeague(null);
      console.log('Leagues not available or no leagueId selected'); // Debugging
    }
  }, [values.leagueId, leagues, setFieldValue]);

  useEffect(() => {
    const fetchLeagues = async () => {
      if (values.country) {
        const countryValue = parseInt(values.country, 10) as Country;
        const response = await getLeagueByCountryAsync(countryValue);
        console.log('Fetched leagues:', response); // Debugging
        setLeagues(response);
        void setFieldValue('leagueId', '');
        void setFieldValue('leagueSeasonStatsId', '');
        void setFieldValue('roundId', '');
        setSelectedLeague(null);
        setLeagueSeasonStats(null);
        setRounds(null);
        setTeams(null);
        setExcludedTeamIds(new Set());
      } else {
        setLeagues(null);
        setSelectedLeague(null);
        setLeagueSeasonStats(null);
        setRounds(null);
        setTeams(null);
        setExcludedTeamIds(new Set());
      }
    };

    void fetchLeagues();
  }, [values.country, getLeagueByCountryAsync, setFieldValue]);

  useEffect(() => {
    if (selectedLeague && values.leagueSeasonStatsId) {
      const seasonStats = selectedLeague.leagueSeasonStats.find(s => s.id === values.leagueSeasonStatsId);
      setLeagueSeasonStats(seasonStats || null);
      setRounds(seasonStats?.rounds || null);
      setTeams(seasonStats?.teams || null);
      void setFieldValue('roundId', '');
    } else {
      setLeagueSeasonStats(null);
      setRounds(null);
      setTeams(null);
    }
  }, [values.leagueSeasonStatsId, selectedLeague, setFieldValue]);

  useEffect(() => {
    if (rounds && values.roundId) {
      const selectedRound = rounds.find(r => r.id === values.roundId);
      if (selectedRound) {
        const playedTeamIds = new Set(selectedRound.matches.flatMap(match => [match.homeTeamId, match.awayTeamId]));
        setExcludedTeamIds(playedTeamIds);
        console.log('Excluded team IDs for the round:', playedTeamIds); // Debugging
      } else {
        setExcludedTeamIds(new Set());
      }
    }
  }, [rounds, values.roundId]);

  const filteredTeams = teams?.filter(team => !excludedTeamIds.has(team.id));

  const availableHomeTeams = filteredTeams?.filter(
    team => team.id !== values.awayTeamStatsId && !excludedTeamIds.has(team.teamId)
  );

  const availableAwayTeams = filteredTeams?.filter(
    team => team.id !== values.homeTeamStatsId && !excludedTeamIds.has(team.teamId)
  );

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <div className="flex items-center mb-6">
          <button type="button" className="text-primary hover:text-primary-dark" onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} />
          </button>
          <h1 className="text-3xl font-bold text-primary text-center flex-grow">Register Match</h1>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg mx-auto space-y-4">
          {/* Country Dropdown */}
          <div>
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Country <span className="text-red-500">*</span>
            </label>
            <select
              name="country"
              className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
              value={values.country}
              onChange={handleChange}
              onBlur={handleBlur}>
              <option value="" label="Select country" />
              {Object.keys(Country)
                .filter(key => isNaN(Number(key)))
                .map(key => {
                  const value = Country[key as keyof typeof Country] as number;
                  return (
                    <option key={key} value={value.toString()}>
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </option>
                  );
                })}
            </select>
            {touched.country && errors.country && <div className="text-red-500 text-sm mt-1">{errors.country}</div>}
          </div>

          {/* League Dropdown */}
          {values.country && (
            <div>
              <label htmlFor="leagueId" className="block text-sm font-medium text-gray-700">
                League <span className="text-red-500">*</span>
              </label>
              <select
                name="leagueId"
                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={values.leagueId}
                onChange={handleChange}
                onBlur={handleBlur}>
                <option value="" label="Select league" />
                {leagues?.map(league => (
                  <option key={league.id} value={league.id}>
                    {league.name}
                  </option>
                ))}
              </select>
              {touched.leagueId && errors.leagueId && (
                <div className="text-red-500 text-sm mt-1">{errors.leagueId}</div>
              )}
            </div>
          )}

          {/* League Season Stats Dropdown */}
          {selectedLeague && (
            <div>
              <label htmlFor="leagueSeasonStatsId" className="block text-sm font-medium text-gray-700">
                League Season Stats <span className="text-red-500">*</span>
              </label>
              <select
                name="leagueSeasonStatsId"
                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={values.leagueSeasonStatsId}
                onChange={handleChange}
                onBlur={handleBlur}>
                <option value="" label="Select League Season Stats" />
                {selectedLeague.leagueSeasonStats.map(stats => (
                  <option key={stats.id} value={stats.id}>
                    {`Season: ${stats.season}`}
                  </option>
                ))}
              </select>
              {touched.leagueSeasonStatsId && errors.leagueSeasonStatsId && (
                <div className="text-red-500 text-sm mt-1">{errors.leagueSeasonStatsId}</div>
              )}
            </div>
          )}

          {/* Round Dropdown */}
          {leagueSeasonStats && (
            <div>
              <label htmlFor="roundId" className="block text-sm font-medium text-gray-700">
                Round <span className="text-red-500">*</span>
              </label>
              <select
                name="roundId"
                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={values.roundId}
                onChange={handleChange}
                onBlur={handleBlur}>
                <option value="" label="Select Round" />
                {rounds?.map(round => (
                  <option key={round.id} value={round.id}>
                    {`Round ${round.roundNumber}`}
                  </option>
                ))}
              </select>
              {touched.roundId && errors.roundId && <div className="text-red-500 text-sm mt-1">{errors.roundId}</div>}
            </div>
          )}

          {/* Home Team Selection */}
          {availableHomeTeams && (
            <div>
              <label htmlFor="homeTeamStatsId" className="block text-sm font-medium text-gray-700">
                Home Team <span className="text-red-500">*</span>
              </label>
              <select
                name="homeTeamStatsId"
                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={values.homeTeamStatsId}
                onChange={handleChange}
                onBlur={handleBlur}>
                <option value="" label="Select Home Team" />
                {availableHomeTeams.map(team => (
                  <option key={team.id} value={team.id}>
                    {team.teamName}
                  </option>
                ))}
              </select>
              {touched.homeTeamStatsId && errors.homeTeamStatsId && (
                <div className="text-red-500 text-sm mt-1">{errors.homeTeamStatsId}</div>
              )}
            </div>
          )}

          {/* Away Team Selection */}
          {availableAwayTeams && (
            <div>
              <label htmlFor="awayTeamStatsId" className="block text-sm font-medium text-gray-700">
                Away Team <span className="text-red-500">*</span>
              </label>
              <select
                name="awayTeamStatsId"
                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={values.awayTeamStatsId}
                onChange={handleChange}
                onBlur={handleBlur}>
                <option value="" label="Select Away Team" />
                {availableAwayTeams.map(team => (
                  <option key={team.id} value={team.id}>
                    {team.teamName}
                  </option>
                ))}
              </select>
              {touched.awayTeamStatsId && errors.awayTeamStatsId && (
                <div className="text-red-500 text-sm mt-1">{errors.awayTeamStatsId}</div>
              )}
            </div>
          )}

          {/* Home Team Goals */}
          <div>
            <label htmlFor="homeTeamGoals" className="block text-sm font-medium text-gray-700">
              Home Team Goals <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="homeTeamGoals"
              min="0"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
              value={values.homeTeamGoals}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.homeTeamGoals && errors.homeTeamGoals && (
              <div className="text-red-500 text-sm mt-1">{errors.homeTeamGoals}</div>
            )}
          </div>

          {/* Away Team Goals */}
          <div>
            <label htmlFor="awayTeamGoals" className="block text-sm font-medium text-gray-700">
              Away Team Goals <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="awayTeamGoals"
              min="0"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
              value={values.awayTeamGoals}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.awayTeamGoals && errors.awayTeamGoals && (
              <div className="text-red-500 text-sm mt-1">{errors.awayTeamGoals}</div>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            Register Match
          </button>
        </form>

        {/* Result Message */}
        {resultMessage && <div className="mt-6 text-center text-lg font-semibold text-primary">{resultMessage}</div>}
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminRegisterMatchPage;
