import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaExclamationTriangle } from 'react-icons/fa';

const NotImplementedPage: React.FC = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState<number>(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    const timeout = setTimeout(() => {
      navigate('/roadmap');
    }, 10000);

    return () => {
      clearInterval(timer);
      clearTimeout(timeout);
    };
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <button className="text-primary hover:text-primary-dark mb-4 self-start" onClick={() => navigate(-1)}>
        <FaArrowLeft size={24} />
      </button>
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <div className="text-yellow-500 text-6xl mb-4">
          <FaExclamationTriangle className="mx-auto" />
        </div>
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Function not implemented</h1>
        <p className="text-lg text-gray-600 mb-6">
          This function is not implemented yet. Returning to our roadmap page in {countdown} seconds...
        </p>
        <button
          className="bg-primary text-white py-2 px-4 rounded-full font-semibold hover:bg-primary-dark transition-colors duration-300"
          onClick={() => navigate('/roadmap')}>
          Go to roadmap page now
        </button>
      </div>
    </div>
  );
};

export default NotImplementedPage;
