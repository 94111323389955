import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { LeagueResponse } from '../../../../../types/apimodels';
import { Country } from '../../../../../types/enums';
import { FaArrowLeft } from 'react-icons/fa';
import { UUID } from 'crypto';

const AdminDetailedLeaguePage: React.FC = () => {
  const { leagueId } = useParams<{ leagueId: UUID }>();
  const apiContext = useContext(ApiContext);
  const { role } = useJwt();
  const navigate = useNavigate();
  const [league, setLeague] = useState<LeagueResponse | null>(null);
  const hasFetchedLeague = useRef(false);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { getLeagueIdAsync, deleteLeagueAsync } = apiContext;

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    } else {
      fetchLeague();
    }
  }, [role, navigate, leagueId]);

  const fetchLeague = async () => {
    if (leagueId && !hasFetchedLeague.current) {
      hasFetchedLeague.current = true;
      const response = await getLeagueIdAsync(leagueId);
      setLeague(response);
    }
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleDeleteLeague = async (leagueId: UUID) => {
    const confirmDelete = window.confirm('Do you really want to delete this league?');
    if (confirmDelete) {
      const success = await deleteLeagueAsync(leagueId);
      if (success) {
        navigate('/admin/leagues/handle');
      } else {
        alert('Failed to delete league');
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        {league ? (
          <div className="flex flex-col items-center">
            {/* Back Button */}
            <button className="text-primary hover:text-primary-dark mb-4 self-start" onClick={() => navigate(-1)}>
              <FaArrowLeft size={24} />
            </button>
            {/* League Info */}
            <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-2xl">
              <h1 className="text-2xl font-semibold text-center mb-4">{league.name}</h1>
              <div className="text-left space-y-2">
                <p>
                  <strong>Country:</strong> {Country[league.country].replace(/([A-Z])/g, ' $1').trim()}
                </p>
                <p>
                  <strong>Number of Teams:</strong> {league.numberOfTeams}
                </p>
                <p>
                  <strong>Number of Rounds:</strong> {league.numberOfRounds}
                </p>
                {/* League Season Stats */}
                {league.leagueSeasonStats && league.leagueSeasonStats.length > 0 && (
                  <div className="mt-4">
                    <h2 className="text-xl font-semibold mb-2">Season Stats</h2>
                    <div className="overflow-x-auto">
                      <table className="min-w-full bg-white">
                        <thead>
                          <tr>
                            <th className="py-2 px-4 bg-gray-100 text-left text-sm font-medium text-gray-700">
                              Season
                            </th>
                            <th className="py-2 px-4 bg-gray-100 text-left text-sm font-medium text-gray-700">Teams</th>
                            <th className="py-2 px-4 bg-gray-100 text-left text-sm font-medium text-gray-700">
                              Rounds
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {league.leagueSeasonStats.map(stat => (
                            <tr key={stat.id} className="border-b">
                              <td className="py-2 px-4 text-sm text-gray-700">{stat.season}</td>
                              <td className="py-2 px-4 text-sm text-gray-700">{stat.teams.length}</td>
                              <td className="py-2 px-4 text-sm text-gray-700">{stat.rounds.length}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
              {/* Action Buttons */}
              <div className="flex justify-center mt-6 space-x-4">
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                  onClick={e => {
                    e.stopPropagation();
                    handleDeleteLeague(league.id);
                  }}>
                  Delete
                </button>
                <button
                  className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600"
                  onClick={e => {
                    e.stopPropagation();
                    handleNavigate(`/admin/leagues/edit/${league.id}`);
                  }}>
                  Update
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center text-gray-500">Loading league details...</div>
        )}
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminDetailedLeaguePage;
