// src/Pages/MyAccount/Profile/AnotherUserPage.tsx
import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import defaultProfilePicture from '../../../Assets/Images/DefaultPP.webp';
import {
  FaSpinner,
  FaUserCircle,
  FaEnvelope,
  FaPhone,
  FaCalendarAlt,
  FaUserFriends,
  FaArrowLeft,
  FaUserPlus,
  FaUserMinus,
  FaPaperPlane,
} from 'react-icons/fa';
import { ApiContext } from '../../../../Contexts/ApiProvider';
import { useJwt } from '../../../../Contexts/JwtProvider';
import {
  FriendProfileUserResponse,
  IOtherUser,
  PrivateProfileUserResponse,
  PublicProfileUserResponse,
  MyFriendRequestsResponse,
} from '../../../../types/apimodels';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import { toast } from 'react-toastify';

const AnotherUserPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const apiContext = useContext(ApiContext);
  const { jwt, role } = useJwt();
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState<IOtherUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<{ text: string; type: 'success' | 'error' } | null>(null);
  const [isProcessingFriendAction, setIsProcessingFriendAction] = useState<boolean>(false);
  const [friendRequests, setFriendRequests] = useState<MyFriendRequestsResponse | null>(null);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { getOtherUserAsync, sendFriendRequestAsync, deleteFriendAsync, acceptFriendRequestAsync } = apiContext;

  useEffect(() => {
    if (role === null) {
      // Optionally, you can allow non-logged-in users to view public profiles
      // navigate('/notauthorized');
    } else {
      fetchFriendRequests();
    }
    fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const fetchUserProfile = async () => {
    setLoading(true);
    try {
      const userData = await getOtherUserAsync(userId!);
      if (userData) {
        setUserProfile(userData);
      } else {
        setMessage({ text: 'User not found.', type: 'error' });
      }
    } catch (error) {
      console.error(error);
      setMessage({ text: 'An error occurred while fetching user data.', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchFriendRequests = async () => {
    if (!apiContext) return;

    try {
      const requests = await apiContext.getMyFriendRequestsAsync();
      setFriendRequests(requests);
    } catch (error) {
      console.error('Failed to fetch friend requests:', error);
      setFriendRequests(null);
    } finally {
    }
  };

  const hasSentFriendRequest = (): boolean => {
    if (!friendRequests || !userProfile) return false;
    return friendRequests.sentRequests.some(request => request.receiverId === userProfile.userId);
  };

  const hasReceivedFriendRequest = (): boolean => {
    if (!friendRequests || !userProfile) return false;
    return friendRequests.receivedRequests.some(request => request.senderId === userProfile.userId);
  };

  const handleNavigate = (path: string, state?: any) => {
    navigate(path, { state });
  };

  const formatDateTime = (date: Date): string => {
    return new Date(date).toLocaleString();
  };

  const renderUserImage = (imageUrl: string | null | undefined) => {
    return (
      <img src={imageUrl || defaultProfilePicture} alt="Profile" className="w-36 h-36 rounded-full object-cover" />
    );
  };

  const handleAddFriend = async () => {
    if (!apiContext || !userProfile) return;

    setIsProcessingFriendAction(true);
    try {
      const success = await sendFriendRequestAsync(userProfile.userId);
      if (success) {
        toast.success('Friend request sent');
        // Refetch friend requests to update UI
        await fetchFriendRequests();
      } else {
        toast.error('Failed to send friend request');
      }
    } catch (error) {
      console.error('Error sending friend request:', error);
      toast.error('An error occurred while sending friend request');
    } finally {
      setIsProcessingFriendAction(false);
    }
  };

  const handleRemoveFriend = async () => {
    if (!apiContext || !userProfile) return;

    setIsProcessingFriendAction(true);
    try {
      const success = await deleteFriendAsync(userProfile.userId);
      if (success) {
        toast.success('Friend removed');
        // Refetch data to update UI
        await fetchUserProfile();
        await fetchFriendRequests();
      } else {
        toast.error('Failed to remove friend');
      }
    } catch (error) {
      console.error('Error removing friend:', error);
      toast.error('An error occurred while removing friend');
    } finally {
      setIsProcessingFriendAction(false);
    }
  };

  const handleAcceptFriendRequest = async () => {
    if (!apiContext || !userProfile) return;

    setIsProcessingFriendAction(true);
    try {
      const success = await acceptFriendRequestAsync(userProfile.userId);
      if (success) {
        toast.success('Friend request accepted');
        // Refetch data to update UI
        await fetchFriendRequests();
        await fetchUserProfile();
      } else {
        toast.error('Failed to accept friend request');
      }
    } catch (error) {
      console.error('Error accepting friend request:', error);
      toast.error('An error occurred while accepting friend request');
    } finally {
      setIsProcessingFriendAction(false);
    }
  };

  const handleSendMessage = () => {
    // Logic to send a message
    navigate('/notimplemented');
  };

  const renderProfileContent = () => {
    if (!userProfile) {
      return null;
    }

    switch (userProfile.type) {
      case 'FriendProfileUserResponse':
        return renderFriendProfile(userProfile as FriendProfileUserResponse);
      case 'PublicProfileUserResponse':
        return renderPublicProfile(userProfile as PublicProfileUserResponse);
      case 'PrivateProfileUserResponse':
        return renderPrivateProfile(userProfile as PrivateProfileUserResponse);
      default:
        return <div className="text-center text-red-500">Unable to display profile information.</div>;
    }
  };

  const renderFriendProfile = (profile: FriendProfileUserResponse) => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {/* Profile Information */}
      <div className="flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow-md mb-6 md:mb-0">
        <div className="mb-6">{renderUserImage(profile.imageUrl)}</div>
        <div className="text-center w-full">
          <div className="flex items-center justify-center mb-4">
            <FaUserCircle className="text-primary text-3xl mr-3" />
            <h1 className="text-2xl font-bold text-primary">
              {profile.firstName} {profile.lastName}
            </h1>
          </div>
          {profile.userName && (
            <div className="flex items-center justify-center mb-2">
              <FaEnvelope className="text-gray-600 text-xl mr-3" />
              <p className="text-lg text-neutral">{profile.userName}</p>
            </div>
          )}
          {profile.phoneNumber && (
            <div className="flex items-center justify-center mb-2">
              <FaPhone className="text-gray-600 text-xl mr-3" />
              <p className="text-lg text-neutral">{profile.phoneNumber}</p>
            </div>
          )}
          <div className="flex items-center justify-center mb-2">
            <FaCalendarAlt className="text-gray-600 text-xl mr-3" />
            <p className="text-lg text-neutral">Member since: {formatDateTime(profile.created)}</p>
          </div>
          {/* Additional Friend Information */}
          <div className="flex items-center justify-center mb-2">
            <FaUserFriends className="text-gray-600 text-xl mr-3" />
            <p className="text-lg text-neutral">This user is your friend.</p>
          </div>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex flex-col items-center md:items-start">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
          <button
            className={`flex items-center justify-center bg-white text-gray-700 border border-gray-300 py-4 px-6 rounded-lg shadow-md hover:bg-gray-100 transition duration-300 ${
              isProcessingFriendAction ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleRemoveFriend}
            disabled={isProcessingFriendAction}>
            {isProcessingFriendAction ? (
              <FaSpinner className="animate-spin mr-2" />
            ) : (
              <FaUserMinus className="mr-2 text-xl" />
            )}
            <span className="text-lg font-semibold">Remove Friend</span>
          </button>
          <button
            className="flex items-center justify-center bg-white text-gray-700 border border-gray-300 py-4 px-6 rounded-lg shadow-md hover:bg-gray-100 transition duration-300"
            onClick={handleSendMessage}>
            <FaPaperPlane className="mr-2 text-xl" />
            <span className="text-lg font-semibold">Send Message</span>
          </button>
        </div>
      </div>
    </div>
  );

  const renderPublicProfile = (profile: PublicProfileUserResponse) => {
    const sentRequest = hasSentFriendRequest();
    const receivedRequest = hasReceivedFriendRequest();

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Profile Information */}
        <div className="flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow-md mb-6 md:mb-0">
          <div className="mb-6">{renderUserImage(profile.imageUrl)}</div>
          <div className="text-center w-full">
            <div className="flex items-center justify-center mb-4">
              <FaUserCircle className="text-primary text-3xl mr-3" />
              <h1 className="text-2xl font-bold text-primary">
                {profile.firstName} {profile.lastName}
              </h1>
            </div>
            {profile.userName && (
              <div className="flex items-center justify-center mb-2">
                <FaEnvelope className="text-gray-600 text-xl mr-3" />
                <p className="text-lg text-neutral">{profile.userName}</p>
              </div>
            )}
            {profile.phoneNumber && (
              <div className="flex items-center justify-center mb-2">
                <FaPhone className="text-gray-600 text-xl mr-3" />
                <p className="text-lg text-neutral">{profile.phoneNumber}</p>
              </div>
            )}
            <div className="flex items-center justify-center mb-2">
              <FaCalendarAlt className="text-gray-600 text-xl mr-3" />
              <p className="text-lg text-neutral">Member since: {formatDateTime(profile.created)}</p>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex flex-col items-center md:items-start">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            {jwt && (
              <>
                {sentRequest ? (
                  <button
                    className="flex items-center justify-center bg-gray-200 text-gray-500 border border-gray-300 py-4 px-6 rounded-lg shadow-md cursor-not-allowed"
                    disabled>
                    <FaUserPlus className="mr-2 text-xl" />
                    <span className="text-lg font-semibold">Request Sent</span>
                  </button>
                ) : receivedRequest ? (
                  <button
                    className="flex items-center justify-center bg-green-500 text-white border border-green-600 py-4 px-6 rounded-lg shadow-md hover:bg-green-600 transition duration-300"
                    onClick={handleAcceptFriendRequest}
                    disabled={isProcessingFriendAction}>
                    {isProcessingFriendAction ? (
                      <FaSpinner className="animate-spin mr-2" />
                    ) : (
                      <FaUserPlus className="mr-2 text-xl" />
                    )}
                    <span className="text-lg font-semibold">Accept Friend Request</span>
                  </button>
                ) : (
                  <button
                    className={`flex items-center justify-center bg-white text-gray-700 border border-gray-300 py-4 px-6 rounded-lg shadow-md hover:bg-gray-100 transition duration-300 ${
                      isProcessingFriendAction ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    onClick={handleAddFriend}
                    disabled={isProcessingFriendAction}>
                    {isProcessingFriendAction ? (
                      <FaSpinner className="animate-spin mr-2" />
                    ) : (
                      <FaUserPlus className="mr-2 text-xl" />
                    )}
                    <span className="text-lg font-semibold">Add Friend</span>
                  </button>
                )}
              </>
            )}
            <button
              className="flex items-center justify-center bg-white text-gray-700 border border-gray-300 py-4 px-6 rounded-lg shadow-md hover:bg-gray-100 transition duration-300"
              onClick={handleSendMessage}>
              <FaPaperPlane className="mr-2 text-xl" />
              <span className="text-lg font-semibold">Send Message</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderPrivateProfile = (profile: PrivateProfileUserResponse) => {
    const sentRequest = hasSentFriendRequest();
    const receivedRequest = hasReceivedFriendRequest();

    return (
      <div className="flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow-md">
        <div className="mb-6">{renderUserImage(null)}</div>
        <div className="text-center w-full">
          <div className="flex items-center justify-center mb-4">
            <FaUserCircle className="text-primary text-3xl mr-3" />
            <h1 className="text-2xl font-bold text-primary">Private Profile</h1>
          </div>
          {profile.userName && (
            <div className="flex items-center justify-center mb-2">
              <FaEnvelope className="text-gray-600 text-xl mr-3" />
              <p className="text-lg text-neutral">{profile.userName}</p>
            </div>
          )}
          <p className="text-lg text-neutral mb-4">This user's profile is private.</p>
          {jwt && (
            <>
              {sentRequest ? (
                <button
                  className="flex items-center justify-center bg-gray-200 text-gray-500 border border-gray-300 py-4 px-6 rounded-lg shadow-md cursor-not-allowed"
                  disabled>
                  <FaUserPlus className="mr-2 text-xl" />
                  <span className="text-lg font-semibold">Friend Request Sent</span>
                </button>
              ) : receivedRequest ? (
                <button
                  className="flex items-center justify-center bg-green-500 text-white border border-green-600 py-4 px-6 rounded-lg shadow-md hover:bg-green-600 transition duration-300"
                  onClick={handleAcceptFriendRequest}
                  disabled={isProcessingFriendAction}>
                  {isProcessingFriendAction ? (
                    <FaSpinner className="animate-spin mr-2" />
                  ) : (
                    <FaUserPlus className="mr-2 text-xl" />
                  )}
                  <span className="text-lg font-semibold">Accept Friend Request</span>
                </button>
              ) : (
                <button
                  className={`flex items-center justify-center bg-white text-gray-700 border border-gray-300 py-4 px-6 rounded-lg shadow-md hover:bg-gray-100 transition duration-300 ${
                    isProcessingFriendAction ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  onClick={handleAddFriend}
                  disabled={isProcessingFriendAction}>
                  {isProcessingFriendAction ? (
                    <FaSpinner className="animate-spin mr-2" />
                  ) : (
                    <FaUserPlus className="mr-2 text-xl" />
                  )}
                  <span className="text-lg font-semibold">Add Friend</span>
                </button>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-6 py-8 pt-20">
        {/* Go Back Arrow */}
        <div className="flex items-center mb-6 cursor-pointer" onClick={() => navigate(-1)}>
          <FaArrowLeft className="text-xl text-primary mr-2" />
          <span className="text-xl text-primary">Back</span>
        </div>

        <div className="bg-white p-8 rounded-lg shadow-lg">
          {loading ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <FaSpinner className="animate-spin text-4xl text-gray-500" />
            </div>
          ) : message ? (
            <div
              className={`text-center p-4 rounded-md ${
                message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
              }`}
              role="alert">
              {message.text}
            </div>
          ) : (
            renderProfileContent()
          )}
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default AnotherUserPage;
