import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { FaArrowLeft } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Season } from '../../../../../types/enums';
import { LeagueSeasonStatsResponse, TeamResponse, LeagueResponse } from '../../../../../types/apimodels';
import { UUID } from 'crypto';

interface FormValues {
  season: string;
  teamIds: string[];
}

const AdminEditLeagueSeasonStatsPage: React.FC = () => {
  const { role } = useJwt();
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { lssId } = useParams<{ lssId: UUID }>();

  const [lss, setLss] = useState<LeagueSeasonStatsResponse | null>(
    (location.state?.lss as LeagueSeasonStatsResponse) || null
  );
  const [league, setLeague] = useState<LeagueResponse | null>(null);
  const [allTeams, setAllTeams] = useState<TeamResponse[] | null>(null);
  const [resultMessage, setResultMessage] = useState<string | null>(null);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { getLssByIdAsync, updateLssAsync, getLeagueIdAsync, getTeamByCountryAsync } = apiContext;

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  // Fetch LSS if not provided via location state
  useEffect(() => {
    const fetchLss = async () => {
      if (!lss && lssId) {
        const response = await getLssByIdAsync(lssId);
        if (response) {
          setLss(response);
        } else {
          setResultMessage('Failed to load League Season Stats data.');
        }
      }
    };
    void fetchLss();
  }, [lssId, lss, getLssByIdAsync]);

  // Once we have LSS, fetch the league
  useEffect(() => {
    const fetchLeague = async () => {
      if (lss && lss.leagueId) {
        const leagueResp = await getLeagueIdAsync(lss.leagueId);
        setLeague(leagueResp || null);
      }
    };
    void fetchLeague();
  }, [lss, getLeagueIdAsync]);

  // Once we have league, fetch all teams from that league's country
  useEffect(() => {
    const fetchTeams = async () => {
      if (league && league.country != null) {
        const teams = await getTeamByCountryAsync(league.country);
        setAllTeams(teams);
      }
    };
    void fetchTeams();
  }, [league, getTeamByCountryAsync]);

  const seasonKeys = Object.keys(Season).filter(key => isNaN(Number(key)));

  // Extract the teamIds from lss.teams. Assume TeamSeasonStatsResponse has a `teamId` field that corresponds to TeamResponse.id
  const prefilledTeamIds = lss ? lss.teams.map(t => t.teamId) : [];

  const validationSchema = Yup.object({
    season: Yup.string().required('Required'),
    teamIds: Yup.array().of(Yup.string().required()).required('Required'),
  });

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      season: lss ? lss.season.toString() : '',
      teamIds: prefilledTeamIds,
    },
    validationSchema,
    onSubmit: async values => {
      const targetId = lssId || lss?.id;
      if (!targetId) {
        setResultMessage('No ID found to update League Season Stats.');
        return;
      }

      if (league && values.teamIds.length > league.numberOfTeams) {
        setResultMessage(`You selected more teams than allowed. Max is ${league.numberOfTeams}.`);
        return;
      }

      const newSeason = parseInt(values.season, 10) as Season;
      const request = {
        id: targetId,
        season: newSeason,
        teamIds: values.teamIds as UUID[],
      };

      const updated = await updateLssAsync(request);
      if (updated) {
        setResultMessage('League Season Stats updated successfully.');
      } else {
        setResultMessage('Failed to update League Season Stats.');
      }
    },
  });

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <div className="flex items-center mb-6">
          <button className="text-primary hover:text-primary-dark" onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} />
          </button>
          <h1 className="text-3xl font-bold text-primary text-center flex-grow">Edit League Season Stats</h1>
        </div>
        {lss ? (
          <form
            onSubmit={formik.handleSubmit}
            className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg mx-auto space-y-4">
            {/* Season */}
            <div>
              <label htmlFor="season" className="block text-sm font-medium text-gray-700">
                Season <span className="text-red-500">*</span>
              </label>
              <select
                name="season"
                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={formik.values.season}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}>
                <option value="" label="Select season" />
                {seasonKeys.map(key => {
                  const value = Season[key as keyof typeof Season] as number;
                  return (
                    <option key={key} value={value.toString()}>
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </option>
                  );
                })}
              </select>
              {formik.touched.season && formik.errors.season && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.season}</div>
              )}
            </div>
            {/* Teams */}
            {allTeams ? (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Teams <span className="text-red-500">*</span> {league && `(Max: ${league.numberOfTeams})`}
                </label>
                <div className="mt-2 max-h-64 overflow-y-auto border border-gray-300 rounded-md p-2">
                  {allTeams.length === 0 ? (
                    <p className="text-sm text-gray-500">No teams available in this country.</p>
                  ) : (
                    allTeams.map(team => (
                      <div key={team.id} className="flex items-center mb-1">
                        <input
                          type="checkbox"
                          id={`team-${team.id}`}
                          name="teamIds"
                          value={team.id}
                          checked={formik.values.teamIds.includes(team.id)}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                        />
                        <label htmlFor={`team-${team.id}`} className="ml-2 text-sm text-gray-700">
                          {team.name}
                        </label>
                      </div>
                    ))
                  )}
                </div>
                {formik.touched.teamIds && formik.errors.teamIds && (
                  <div className="text-red-500 text-sm mt-1">{formik.errors.teamIds as string}</div>
                )}
              </div>
            ) : (
              <p className="text-center text-gray-500">Loading teams...</p>
            )}

            <button
              type="submit"
              className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
              Update League Season Stats
            </button>
          </form>
        ) : (
          <div className="text-center text-gray-500">Loading League Season Stats details...</div>
        )}
        {resultMessage && <div className="mt-6 text-center text-lg font-semibold text-primary">{resultMessage}</div>}
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminEditLeagueSeasonStatsPage;
