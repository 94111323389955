import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { FaArrowLeft, FaTrash } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Country } from '../../../../../types/enums';
import {
  LeagueResponse,
  LeagueSeasonStatsResponse,
  RoundResponse,
  TeamSeasonStatsResponse,
} from '../../../../../types/apimodels';

interface MatchInput {
  homeTeamStatsId: string;
  awayTeamStatsId: string;
  homeTeamGoals: number;
  awayTeamGoals: number;
}

interface FormValues {
  country: string;
  leagueId: string;
  leagueSeasonStatsId: string;
  roundId: string;
  matches: MatchInput[];
}

const AdminRegisterMatchesPage: React.FC = () => {
  const { role } = useJwt();
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();

  const [resultMessage, setResultMessage] = useState<string | null>(null);
  const [leagues, setLeagues] = useState<LeagueResponse[] | null>(null);
  const [selectedLeague, setSelectedLeague] = useState<LeagueResponse | null>(null);
  const [leagueSeasonStats, setLeagueSeasonStats] = useState<LeagueSeasonStatsResponse | null>(null);
  const [rounds, setRounds] = useState<RoundResponse[] | null>(null);
  const [teams, setTeams] = useState<TeamSeasonStatsResponse[] | null>(null);

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { registerMatchesAsync, getLeagueByCountryAsync } = apiContext;

  const validationSchema = Yup.object({
    country: Yup.string().required('Required'),
    leagueId: Yup.string().required('Required'),
    leagueSeasonStatsId: Yup.string().required('Required'),
    roundId: Yup.string().required('Required'),
    matches: Yup.array()
      .of(
        Yup.object().shape({
          homeTeamStatsId: Yup.string().required('Required'),
          awayTeamStatsId: Yup.string()
            .required('Required')
            .notOneOf([Yup.ref('homeTeamStatsId')], 'Teams must be different'),
          homeTeamGoals: Yup.number().min(0, 'Must be at least 0').required('Required'),
          awayTeamGoals: Yup.number().min(0, 'Must be at least 0').required('Required'),
        })
      )
      .required('Matches are required'),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      country: '',
      leagueId: '',
      leagueSeasonStatsId: '',
      roundId: '',
      matches: [],
    },
    validationSchema,
    onSubmit: async values => {
      const success = await registerMatchesAsync(
        values.matches.map(match => ({
          ...match,
          roundId: values.roundId as `${string}-${string}-${string}-${string}-${string}`,
          homeTeamStatsId: match.homeTeamStatsId as `${string}-${string}-${string}-${string}-${string}`,
          awayTeamStatsId: match.awayTeamStatsId as `${string}-${string}-${string}-${string}-${string}`,
        }))
      );

      if (success) {
        setResultMessage('Matches registered successfully.');
        formik.resetForm();
        setLeagues(null);
        setSelectedLeague(null);
        setLeagueSeasonStats(null);
        setRounds(null);
        setTeams(null);
      } else {
        setResultMessage('Failed to register matches.');
      }
    },
  });

  const { values, handleChange, handleBlur, touched, errors, setFieldValue } = formik;

  useEffect(() => {
    const fetchLeagues = async () => {
      if (values.country) {
        const countryValue = parseInt(values.country, 10) as Country;
        const response = await getLeagueByCountryAsync(countryValue);
        setLeagues(response);
        setFieldValue('leagueId', '');
        setFieldValue('leagueSeasonStatsId', '');
        setFieldValue('roundId', '');
        setFieldValue('matches', []);
        setSelectedLeague(null);
        setLeagueSeasonStats(null);
        setRounds(null);
        setTeams(null);
      }
    };

    void fetchLeagues();
  }, [values.country, getLeagueByCountryAsync, setFieldValue]);

  useEffect(() => {
    if (leagues && values.leagueId) {
      const league = leagues.find(l => l.id === values.leagueId);
      setSelectedLeague(league || null);
      setFieldValue('leagueSeasonStatsId', '');
      setFieldValue('roundId', '');
      setFieldValue('matches', []);
      setLeagueSeasonStats(null);
      setRounds(null);
      setTeams(null);
    }
  }, [values.leagueId, leagues, setFieldValue]);

  useEffect(() => {
    if (selectedLeague && values.leagueSeasonStatsId) {
      const seasonStats = selectedLeague.leagueSeasonStats.find(s => s.id === values.leagueSeasonStatsId);
      setLeagueSeasonStats(seasonStats || null);
      setRounds(seasonStats?.rounds || null);
      setTeams(seasonStats?.teams || null);
      setFieldValue('roundId', '');
      setFieldValue('matches', []);
    }
  }, [values.leagueSeasonStatsId, selectedLeague, setFieldValue]);

  const handleMatchChange = (index: number, field: string, value: any) => {
    const updatedMatches = [...values.matches];
    updatedMatches[index] = { ...updatedMatches[index], [field]: value };
    setFieldValue('matches', updatedMatches);
  };

  const addMatch = () => {
    const maxMatches = (leagueSeasonStats?.teams.length || 0) / 2;
    if (values.matches.length >= maxMatches) return;
    setFieldValue('matches', [
      ...values.matches,
      { homeTeamStatsId: '', awayTeamStatsId: '', homeTeamGoals: 0, awayTeamGoals: 0 },
    ]);
  };

  const removeMatch = (index: number) => {
    const updatedMatches = [...values.matches];
    updatedMatches.splice(index, 1);
    setFieldValue('matches', updatedMatches);
  };
  new Set(values.matches.flatMap(match => [match.homeTeamStatsId, match.awayTeamStatsId]));
  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <div className="flex items-center mb-6">
          <button className="text-primary hover:text-primary-dark" onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} />
          </button>
          <h1 className="text-3xl font-bold text-primary text-center flex-grow">Register Matches for Round</h1>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg mx-auto space-y-4">
          {/* Country Dropdown */}
          <div>
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Country <span className="text-red-500">*</span>
            </label>
            <select
              name="country"
              className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
              value={values.country}
              onChange={handleChange}
              onBlur={handleBlur}>
              <option value="" label="Select country" />
              {Object.keys(Country)
                .filter(key => isNaN(Number(key)))
                .map(key => {
                  const value = Country[key as keyof typeof Country] as number;
                  return (
                    <option key={key} value={value.toString()}>
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </option>
                  );
                })}
            </select>
            {touched.country && errors.country && <div className="text-red-500 text-sm mt-1">{errors.country}</div>}
          </div>

          {/* League Dropdown */}
          {values.country && leagues && (
            <div>
              <label htmlFor="leagueId" className="block text-sm font-medium text-gray-700">
                League <span className="text-red-500">*</span>
              </label>
              <select
                name="leagueId"
                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={values.leagueId}
                onChange={handleChange}
                onBlur={handleBlur}>
                <option value="" label="Select league" />
                {leagues.map(league => (
                  <option key={league.id} value={league.id}>
                    {league.name}
                  </option>
                ))}
              </select>
              {touched.leagueId && errors.leagueId && (
                <div className="text-red-500 text-sm mt-1">{errors.leagueId}</div>
              )}
            </div>
          )}

          {/* League Season Stats Dropdown */}
          {selectedLeague && (
            <div>
              <label htmlFor="leagueSeasonStatsId" className="block text-sm font-medium text-gray-700">
                League Season Stats <span className="text-red-500">*</span>
              </label>
              <select
                name="leagueSeasonStatsId"
                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={values.leagueSeasonStatsId}
                onChange={handleChange}
                onBlur={handleBlur}>
                <option value="" label="Select League Season Stats" />
                {selectedLeague.leagueSeasonStats.map(stats => (
                  <option key={stats.id} value={stats.id}>
                    {`Season: ${stats.season}`}
                  </option>
                ))}
              </select>
              {touched.leagueSeasonStatsId && errors.leagueSeasonStatsId && (
                <div className="text-red-500 text-sm mt-1">{errors.leagueSeasonStatsId}</div>
              )}
            </div>
          )}

          {/* Round Dropdown */}
          {leagueSeasonStats && rounds && (
            <div>
              <label htmlFor="roundId" className="block text-sm font-medium text-gray-700">
                Round <span className="text-red-500">*</span>
              </label>
              <select
                name="roundId"
                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={values.roundId}
                onChange={handleChange}
                onBlur={handleBlur}>
                <option value="" label="Select Round" />
                {rounds.map(round => (
                  <option key={round.id} value={round.id}>
                    {`Round ${round.roundNumber}`}
                  </option>
                ))}
              </select>
              {touched.roundId && errors.roundId && <div className="text-red-500 text-sm mt-1">{errors.roundId}</div>}
            </div>
          )}

          {/* Matches */}
          {values.roundId && teams && (
            <div>
              <h2 className="text-lg font-semibold text-gray-700 mb-2">Matches</h2>
              {values.matches.map((match, index) => (
                <div key={index} className="mb-4 border rounded-lg p-4 relative">
                  <div className="absolute top-2 right-2">
                    <button
                      type="button"
                      onClick={() => removeMatch(index)}
                      className="bg-red-500 text-white rounded-full p-2 hover:bg-red-600 focus:outline-none">
                      <FaTrash />
                    </button>
                  </div>
                  <h3 className="text-lg font-semibold text-gray-700 mb-2">Match {index + 1}</h3>
                  <div className="mb-2">
                    <label
                      htmlFor={`matches.${index}.homeTeamStatsId`}
                      className="block text-sm font-medium text-gray-700">
                      Home Team <span className="text-red-500">*</span>
                    </label>
                    <select
                      name={`matches.${index}.homeTeamStatsId`}
                      value={match.homeTeamStatsId}
                      onChange={e => handleMatchChange(index, 'homeTeamStatsId', e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm">
                      <option value="" label="Select Home Team" />
                      {teams &&
                        teams
                          .filter(team => {
                            const isSelected = team.id === match.homeTeamStatsId || team.id === match.awayTeamStatsId;
                            const isAlreadyUsed = values.matches.some(
                              (otherMatch, otherIndex) =>
                                otherIndex !== index &&
                                (otherMatch.homeTeamStatsId === team.id || otherMatch.awayTeamStatsId === team.id)
                            );
                            return isSelected || !isAlreadyUsed;
                          })
                          .map(team => (
                            <option key={team.id} value={team.id}>
                              {team.teamName}
                            </option>
                          ))}
                    </select>
                  </div>

                  <div className="mb-2">
                    <label
                      htmlFor={`matches.${index}.awayTeamStatsId`}
                      className="block text-sm font-medium text-gray-700">
                      Away Team <span className="text-red-500">*</span>
                    </label>
                    <select
                      name={`matches.${index}.awayTeamStatsId`}
                      value={match.awayTeamStatsId}
                      onChange={e => handleMatchChange(index, 'awayTeamStatsId', e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm">
                      <option value="" label="Select Away Team" />
                      {teams &&
                        teams
                          .filter(team => {
                            const isSelected = team.id === match.homeTeamStatsId || team.id === match.awayTeamStatsId;
                            const isAlreadyUsed = values.matches.some(
                              (otherMatch, otherIndex) =>
                                otherIndex !== index &&
                                (otherMatch.homeTeamStatsId === team.id || otherMatch.awayTeamStatsId === team.id)
                            );
                            return isSelected || !isAlreadyUsed;
                          })
                          .map(team => (
                            <option key={team.id} value={team.id}>
                              {team.teamName}
                            </option>
                          ))}
                    </select>
                  </div>

                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <label
                        htmlFor={`matches.${index}.homeTeamGoals`}
                        className="block text-sm font-medium text-gray-700">
                        Home Team Goals <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        name={`matches.${index}.homeTeamGoals`}
                        value={match.homeTeamGoals}
                        onChange={e => handleMatchChange(index, 'homeTeamGoals', Number(e.target.value))}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                        min={0}
                      />
                    </div>
                    <div className="w-1/2">
                      <label
                        htmlFor={`matches.${index}.awayTeamGoals`}
                        className="block text-sm font-medium text-gray-700">
                        Away Team Goals <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        name={`matches.${index}.awayTeamGoals`}
                        value={match.awayTeamGoals}
                        onChange={e => handleMatchChange(index, 'awayTeamGoals', Number(e.target.value))}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                        min={0}
                      />
                    </div>
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={addMatch}
                disabled={values.matches.length >= (leagueSeasonStats?.teams.length || 0) / 2}
                className={`w-full py-2 px-4 rounded-md ${
                  values.matches.length >= (leagueSeasonStats?.teams.length || 0) / 2
                    ? 'bg-gray-300 text-gray-500'
                    : 'bg-primary text-white hover:bg-primary-dark'
                }`}>
                Add Match
              </button>
            </div>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            Register Matches
          </button>
        </form>

        {/* Result Message */}
        {resultMessage && <div className="mt-6 text-center text-lg font-semibold text-primary">{resultMessage}</div>}
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminRegisterMatchesPage;
