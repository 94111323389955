import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { FaArrowLeft } from 'react-icons/fa';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import MainHeader from '../../../../Partials/Header/MainHeader';
import { UUID } from 'crypto';
import { RoundResponse } from '../../../../../types/apimodels';

interface FormValues {
  roundDate: string;
}

const AdminEditRoundPage: React.FC = () => {
  const { role } = useJwt();
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { roundId } = useParams<{ roundId: UUID }>();
  const [round, setRound] = useState<RoundResponse | null>(location.state?.round || null);
  const [resultMessage, setResultMessage] = useState<string | null>(null);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { updateRoundAsync, getRoundByIdAsync } = apiContext;

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  useEffect(() => {
    const fetchRoundIfNeeded = async () => {
      if (!round && roundId) {
        const response = await getRoundByIdAsync(roundId);
        // Ensure `deadline` is a Date object
        if (response) {
          response.deadline = new Date(response.deadline);
        }
        setRound(response);
      }
    };
    void fetchRoundIfNeeded();
  }, [round, roundId, getRoundByIdAsync]);

  // Once round is available, prefill the date
  const prefilledDateTime = round
    ? new Date(round.deadline).toISOString().slice(0, 16) // Safely handle as Date object
    : '';

  const validationSchema = Yup.object({
    roundDate: Yup.string().required('Required'),
  });

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      roundDate: prefilledDateTime,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      if (!roundId) return;
      const deadline = new Date(values.roundDate);

      const request = {
        id: roundId,
        Deadline: deadline,
      };
      const response = await updateRoundAsync(request);
      if (response) {
        setResultMessage('Round updated successfully');
      } else {
        setResultMessage('Failed to update round');
      }
    },
  });

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <div className="flex items-center mb-6">
          <button className="text-primary hover:text-primary-dark" onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} />
          </button>
          <h1 className="text-3xl font-bold text-primary text-center flex-grow">Update Round</h1>
        </div>
        {round ? (
          <form onSubmit={formik.handleSubmit} className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg mx-auto">
            {/* Round Date */}
            <div className="mb-4">
              <label htmlFor="roundDate" className="block text-sm font-medium text-gray-700">
                Round Date <span className="text-red-500">*</span>
              </label>
              <input
                type="datetime-local"
                name="roundDate"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={formik.values.roundDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.roundDate && formik.errors.roundDate && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.roundDate}</div>
              )}
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
              Update Round
            </button>
          </form>
        ) : (
          <div className="text-center text-gray-500">Loading round details...</div>
        )}
        {resultMessage && <div className="mt-6 text-center text-lg font-semibold text-primary">{resultMessage}</div>}
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminEditRoundPage;
