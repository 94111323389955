import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { TeamResponse } from '../../../../../types/apimodels';
import { Country } from '../../../../../types/enums';
import defaultTeamImage from '../../../../Assets/Images/DefaultPP.webp';
import { FaArrowLeft } from 'react-icons/fa';
import { UUID } from 'crypto';

const AdminDetailedTeamPage: React.FC = () => {
  const { teamId } = useParams<{ teamId: UUID }>();
  const apiContext = useContext(ApiContext);
  const { role } = useJwt();
  const navigate = useNavigate();
  const [team, setTeam] = useState<TeamResponse | null>(null);
  const hasFetchedTeam = useRef(false);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { getTeamByIdAsync, deleteTeamAsync } = apiContext;

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    } else {
      fetchTeam();
    }
  }, [role, navigate, teamId]);

  const fetchTeam = async () => {
    if (teamId && !hasFetchedTeam.current) {
      hasFetchedTeam.current = true;
      const response = await getTeamByIdAsync(teamId);
      setTeam(response);
    }
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleDeleteTeam = async (teamId: UUID) => {
    const confirmDelete = window.confirm('Do you really want to delete this team?');
    if (confirmDelete) {
      const success = await deleteTeamAsync(teamId);
      if (success) {
        navigate('/admin/teams/handle');
      } else {
        alert('Failed to delete team');
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <button className="text-primary hover:text-primary-dark mb-4 self-start" onClick={() => navigate(-1)}>
          <FaArrowLeft size={24} />
        </button>
        {team ? (
          <div className="flex flex-col items-center">
            {/* Team Image */}
            <div className="mb-6">
              <img src={team.imageUrl || defaultTeamImage} alt="Team" className="w-36 h-36 rounded-full object-cover" />
            </div>
            {/* Team Info */}
            <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-2xl">
              <h1 className="text-2xl font-semibold text-center mb-4">{team.name}</h1>
              <div className="text-left space-y-2">
                <p>
                  <strong>English Name:</strong> {team.englishName || 'N/A'}
                </p>
                <p>
                  <strong>Country:</strong> {Country[team.country].replace(/([A-Z])/g, ' $1').trim()}
                </p>
                {team.website && (
                  <p>
                    <strong>Website:</strong>{' '}
                    <a
                      href={team.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline">
                      {team.website}
                    </a>
                  </p>
                )}
                {/* Season Stats */}
                {team.seasonStats && team.seasonStats.length > 0 && (
                  <div className="mt-4">
                    <h2 className="text-xl font-semibold mb-2">Season Stats</h2>
                    <div className="overflow-x-auto">
                      <table className="min-w-full bg-white">
                        <thead>
                          <tr>
                            <th className="py-2 px-4 bg-gray-100 text-left text-sm font-medium text-gray-700">
                              Season
                            </th>
                            <th className="py-2 px-4 bg-gray-100 text-left text-sm font-medium text-gray-700">
                              Goals For
                            </th>
                            <th className="py-2 px-4 bg-gray-100 text-left text-sm font-medium text-gray-700">
                              Goals Against
                            </th>
                            <th className="py-2 px-4 bg-gray-100 text-left text-sm font-medium text-gray-700">
                              Points
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {team.seasonStats.map(stat => (
                            <tr key={stat.id} className="border-b">
                              <td className="py-2 px-4 text-sm text-gray-700">{stat.season}</td>
                              <td className="py-2 px-4 text-sm text-gray-700">{stat.goalsFor}</td>
                              <td className="py-2 px-4 text-sm text-gray-700">{stat.goalsAgainst}</td>
                              <td className="py-2 px-4 text-sm text-gray-700">{stat.points}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
              {/* Action Buttons */}
              <div className="flex justify-center mt-6 space-x-4">
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                  onClick={e => {
                    e.stopPropagation();
                    handleDeleteTeam(team.id);
                  }}>
                  Delete
                </button>
                <button
                  className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600"
                  onClick={e => {
                    e.stopPropagation();
                    handleNavigate(`/admin/teams/edit/${team.id}`);
                  }}>
                  Update
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center text-gray-500">Loading team details...</div>
        )}
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminDetailedTeamPage;
