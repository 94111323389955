import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import HomePage from './Components/Views/Home/HomePage';
import RegisterPage from './Components/Views/Register/RegisterPage';
import LoginPage from './Components/Views/Login/LoginPage';
import AdminPage from './Components/Views/User/Admin/AdminPage';
import NotAuthorizedPage from './Components/Views/Errors/NotAuthorized/NotAuthorizedPage';
import { JwtProvider } from './Contexts/JwtProvider';
import ApiProvider from './Contexts/ApiProvider';
import AdminRegisterUserPage from './Components/Views/User/Admin/Users/AdminRegisterUserPage';
import AdminHandleUsersPage from './Components/Views/User/Admin/Users/AdminHandleUsersPage';
import AdminUpdateUserPage from './Components/Views/User/Admin/Users/AdminUpdateUserPage';
import ConfirmEmail from './Components/Views/Confirmation/Email/ConfirmEmail';
import MyAccountPage from './Components/Views/User/User/MyAccountPage';
import UpdateMyUserPage from './Components/Views/User/User/UpdateMyUserPage';
import AdminDetailedUserPage from './Components/Views/User/Admin/Users/AdminDetailedUserPage';
import ResetPassword from './Components/Views/Confirmation/Password/ResetPassword';
import ForgottenPassword from './Components/Views/Confirmation/Password/ForgottenPassword';
import MyFriendsPage from './Components/Views/User/User/MyFriendsPage';
import AboutPage from './Components/Views/Company/About/AboutPage';
import TeamPage from './Components/Views/Company/Team/TeamPage';
import CareerPage from './Components/Views/Company/Career/CareerPage';
import PartnersPage from './Components/Views/Company/Partners/PartnersPage';
import NewsPage from './Components/Views/Company/News/NewsPage';
import RoadmapPage from './Components/Views/Product/Roadmap/RoadmapPage';
import LeaguesPage from './Components/Views/Product/Leagues/LeaguesPage';
import LeaderboardsPage from './Components/Views/Product/Leaderboards/LeaderboardsPage';
import TermsPage from './Components/Views/Legal/Terms/TermsPage';
import PrivacyPage from './Components/Views/Legal/Privacy/PrivacyPage';
import AdminUsersPage from './Components/Views/User/Admin/Users/AdminUsersPage';
import MyAccountTablesPage from './Components/Views/User/User/MyAccountTablesPage';
import MyAccountProfilePage from './Components/Views/User/User/MyAccountProfilePage';
import NewSimpleTablePage from './Components/Views/User/Tables/NewSimpleTablePage';
import AdminTeamsPage from './Components/Views/User/Admin/Teams/AdminTeamsPage';
import AdminMatchesPage from './Components/Views/User/Admin/Matches/AdminMatchesPage';
import AdminTeamSeasonStatsPage from './Components/Views/User/Admin/Teams/AdminTeamSeasonStatsPage';
import AdminLeagueSeasonStatsPage from './Components/Views/User/Admin/Leagues/AdminLeagueSeasonStatsPage';
import AdminRoundsPage from './Components/Views/User/Admin/Rounds/AdminRoundsPage';
import AdminLeaguesPage from './Components/Views/User/Admin/Leagues/AdminLeaguesPage';
import AdminRegisterTeamPage from './Components/Views/User/Admin/Teams/AdminRegisterTeamPage';
import AdminHandleTeamsPage from './Components/Views/User/Admin/Teams/AdminHandleTeamsPage';
import AdminDetailedTeamPage from './Components/Views/User/Admin/Teams/AdminDetailedTeamPage';
import AdminRegisterLeaguePage from './Components/Views/User/Admin/Leagues/AdminRegisterLeaguePage';
import AdminHandleLeaguesPage from './Components/Views/User/Admin/Leagues/AdminHandleLeaguesPage';
import AdminDetailedLeaguePage from './Components/Views/User/Admin/Leagues/AdminDetailedLeaguePage';
import AdminEditLeaguePage from './Components/Views/User/Admin/Leagues/AdminEditLeaguePage';
import AdminEditTeamPage from './Components/Views/User/Admin/Teams/AdminEditTeamPage';
import PublicSimpleTablePage from './Components/Views/PublicPages/Table/PublicSimpleTablePage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OfficialTablesPage from './Components/Views/PublicPages/Table/OfficialTablesPage';
import PublicNewSimpleTablePage from './Components/Views/PublicPages/Table/PublicNewSimpleTablePage';
import ContactPage from './Components/Views/PublicPages/ContactPage';
import NotFoundPage from './Components/Views/Errors/NotFound/NotFoundPage';
import AnotherUserPage from './Components/Views/User/User/AnotherUserPage';
import NotImplementedPage from './Components/Views/Errors/NotImplemented/NotImplementedPage';
import AdminRegisterRoundPage from './Components/Views/User/Admin/Rounds/AdminRegisterRoundPage';
import AdminHandleRoundsPage from './Components/Views/User/Admin/Rounds/AdminHandleRoundsPage';
import AdminEditRoundPage from './Components/Views/User/Admin/Rounds/AdminEditRoundPage';
import AdminRegisterLeagueSeasonStatsPage from './Components/Views/User/Admin/Leagues/AdminRegisterLeagueSeasonStatsPage';
import AdminHandleLeagueSeasonStatsPage from './Components/Views/User/Admin/Leagues/AdminHandleLeagueSeasonStatsPage';
import AdminEditLeagueSeasonStatsPage from './Components/Views/User/Admin/Leagues/AdminEditLeagueSeasonStatsPage';
import AdminRegisterTeamSeasonStatsPage from './Components/Views/User/Admin/Teams/AdminRegisterTeamSeasonStatsPage';
import AdminRegisterMatchPage from './Components/Views/User/Admin/Matches/AdminRegisterMatchPage';
import AdminHandleMatchesPage from './Components/Views/User/Admin/Matches/AdminHandleMatchesPage';
import AdminEditMatchPage from './Components/Views/User/Admin/Matches/AdminEditMatchPage';
import AdminHandleTeamSeasonStatsPage from './Components/Views/User/Admin/Teams/AdminHandleTeamSeasonStatsPage';
import AdminEditTeamSeasonStatsPage from './Components/Views/User/Admin/Teams/AdminEditTeamSeasonStatsPage';
import AdminRegisterMatchesPage from './Components/Views/User/Admin/Matches/AdminRegisterMatchesPage';

function App() {
  return (
    <JwtProvider>
      <ApiProvider>
        <ToastContainer
          position="bottom-center" // Position of the toast notifications
          autoClose={5000} // Auto-close delay in milliseconds
          hideProgressBar={true} // Show or hide progress bar
          newestOnTop={true} // Display new toasts on top
          closeOnClick // Close toast on click
          rtl={false} // Right-to-left support
          pauseOnFocusLoss // Pause auto-close when window loses focus
          draggable // Allow dragging to dismiss
          pauseOnHover // Pause auto-close on hover
          theme="colored" // Theme: "light", "dark", or "colored"
        />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/forgottenpassword" element={<ForgottenPassword />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/notauthorized" element={<NotAuthorizedPage />} />
          <Route path="/notimplemented" element={<NotImplementedPage />} />
          <Route path="/admin/users/update/:userId" element={<AdminUpdateUserPage />} />
          <Route path="/admin/users/detailed/:userId" element={<AdminDetailedUserPage />} />
          <Route path="/admin/users/register" element={<AdminRegisterUserPage />} />
          <Route path="/admin/users/handle" element={<AdminHandleUsersPage />} />
          <Route path="/admin/users" element={<AdminUsersPage />} />
          <Route path="/admin/leagues" element={<AdminLeaguesPage />} />
          <Route path="/admin/leagues/register" element={<AdminRegisterLeaguePage />} />
          <Route path="/admin/leagues/handle" element={<AdminHandleLeaguesPage />} />
          <Route path="/admin/leagues/detail/:leagueId" element={<AdminDetailedLeaguePage />} />
          <Route path="/admin/leagues/edit/:leagueId" element={<AdminEditLeaguePage />} />
          <Route path="/admin/matches" element={<AdminMatchesPage />} />
          <Route path="/admin/matches/register" element={<AdminRegisterMatchPage />} />
          <Route path="/admin/matches/register/round" element={<AdminRegisterMatchesPage />} />
          <Route path="/admin/matches/handle" element={<AdminHandleMatchesPage />} />
          <Route path="/admin/matches/edit/:matchId" element={<AdminEditMatchPage />} />
          <Route path="/admin/rounds" element={<AdminRoundsPage />} />
          <Route path="/admin/rounds/handle" element={<AdminHandleRoundsPage />} />
          <Route path="/admin/rounds/edit/:roundId" element={<AdminEditRoundPage />} />
          <Route path="/admin/rounds/register" element={<AdminRegisterRoundPage />} />
          <Route path="/admin/lss" element={<AdminLeagueSeasonStatsPage />} />
          <Route path="/admin/lss/register" element={<AdminRegisterLeagueSeasonStatsPage />} />
          <Route path="/admin/lss/handle" element={<AdminHandleLeagueSeasonStatsPage />} />
          <Route path="/admin/lss/edit/:lssId" element={<AdminEditLeagueSeasonStatsPage />} />
          <Route path="/admin/tss" element={<AdminTeamSeasonStatsPage />} />
          <Route path="/admin/tss/register" element={<AdminRegisterTeamSeasonStatsPage />} />
          <Route path="/admin/tss/handle" element={<AdminHandleTeamSeasonStatsPage />} />
          <Route path="/admin/tss/edit/:tssId" element={<AdminEditTeamSeasonStatsPage />} />
          <Route path="/admin/teams" element={<AdminTeamsPage />} />
          <Route path="/admin/teams/register" element={<AdminRegisterTeamPage />} />
          <Route path="/admin/teams/handle" element={<AdminHandleTeamsPage />} />
          <Route path="/admin/teams/detail/:teamId" element={<AdminDetailedTeamPage />} />
          <Route path="/admin/teams/edit/:teamId" element={<AdminEditTeamPage />} />
          <Route path="/simpletable/:tableId" element={<PublicSimpleTablePage />} />
          <Route path="/confirmemail" element={<ConfirmEmail />} />
          <Route path="/my-account" element={<MyAccountPage />} />
          <Route path="/my-account/tables" element={<MyAccountTablesPage />} />
          <Route path="/my-account/tables/simpletables/createnew" element={<NewSimpleTablePage />} />
          <Route path="/my-account/profile" element={<MyAccountProfilePage />} />
          <Route path="/my-account/profile/friends" element={<MyFriendsPage />} />
          <Route path="/official-tables" element={<OfficialTablesPage />} />
          <Route path="/create-table" element={<PublicNewSimpleTablePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/our-team" element={<TeamPage />} />
          <Route path="/career" element={<CareerPage />} />
          <Route path="/partners" element={<PartnersPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/roadmap" element={<RoadmapPage />} />
          <Route path="/leagues" element={<LeaguesPage />} />
          <Route path="/leaderboards" element={<LeaderboardsPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/my-account/profile/update-profile" element={<UpdateMyUserPage />} />
          <Route path="/userprofile/:userId" element={<AnotherUserPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ApiProvider>
    </JwtProvider>
  );
}

export default App;
