import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { FaArrowLeft } from 'react-icons/fa';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import MainHeader from '../../../../Partials/Header/MainHeader';
import { UUID } from 'crypto';
import { MatchResponse, UpdateMatchRequest } from '../../../../../types/apimodels';

interface FormValues {
  homeTeamGoals: number;
  awayTeamGoals: number;
}

const AdminEditMatchPage: React.FC = () => {
  const { role } = useJwt();
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { matchId } = useParams<{ matchId: UUID }>();
  const [match, setMatch] = useState<MatchResponse | null>(location.state?.match || null);
  const [resultMessage, setResultMessage] = useState<string | null>(null);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { getMatchByIdAsync, updateMatchAsync, deleteMatchAsync } = apiContext;

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  useEffect(() => {
    const fetchMatchIfNeeded = async () => {
      if (!match && matchId) {
        const response = await getMatchByIdAsync(matchId);
        setMatch(response);
      }
    };
    void fetchMatchIfNeeded();
  }, [match, matchId, getMatchByIdAsync]);

  const validationSchema = Yup.object({
    homeTeamGoals: Yup.number()
      .min(0, 'Goals cannot be negative')
      .max(200, 'Goals cannot exceed 200')
      .required('Required'),
    awayTeamGoals: Yup.number()
      .min(0, 'Goals cannot be negative')
      .max(200, 'Goals cannot exceed 200')
      .required('Required'),
  });

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      homeTeamGoals: match?.homeTeamGoals || 0,
      awayTeamGoals: match?.awayTeamGoals || 0,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      if (!matchId) return;
      const request: UpdateMatchRequest = {
        id: matchId,
        homeTeamGoals: values.homeTeamGoals,
        awayTeamGoals: values.awayTeamGoals,
      };

      const response = await updateMatchAsync(request);
      if (response) {
        setResultMessage('Match updated successfully');
        setMatch(response); // Update local state with the response
      } else {
        setResultMessage('Failed to update match');
      }
    },
  });

  const handleDelete = async () => {
    if (!matchId) return;
    const confirmDelete = window.confirm('Do you really want to delete this match?');
    if (confirmDelete) {
      const success = await deleteMatchAsync(matchId);
      if (success) {
        setResultMessage('Match deleted successfully');
        setTimeout(() => navigate(-1), 2000); // Navigate back after 2 seconds
      } else {
        setResultMessage('Failed to delete match');
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <div className="flex items-center mb-6">
          <button className="text-primary hover:text-primary-dark" onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} />
          </button>
          <h1 className="text-3xl font-bold text-primary text-center flex-grow">Edit Match</h1>
        </div>
        {match ? (
          <form onSubmit={formik.handleSubmit} className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg mx-auto">
            {/* Match Info */}
            <div className="mb-4 text-center">
              <h2 className="text-xl font-semibold">
                {match.homeTeamName} vs {match.awayTeamName}
              </h2>
            </div>

            {/* Home Team Goals */}
            <div className="mb-4">
              <label htmlFor="homeTeamGoals" className="block text-sm font-medium text-gray-700">
                {match.homeTeamName} Goals <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="homeTeamGoals"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={formik.values.homeTeamGoals}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.homeTeamGoals && formik.errors.homeTeamGoals && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.homeTeamGoals}</div>
              )}
            </div>

            {/* Away Team Goals */}
            <div className="mb-4">
              <label htmlFor="awayTeamGoals" className="block text-sm font-medium text-gray-700">
                {match.awayTeamName} Goals <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="awayTeamGoals"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={formik.values.awayTeamGoals}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.awayTeamGoals && formik.errors.awayTeamGoals && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.awayTeamGoals}</div>
              )}
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
              Update Match
            </button>

            {/* Delete Button */}
            <button
              type="button"
              className="w-full mt-4 bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={handleDelete}>
              Delete Match
            </button>
          </form>
        ) : (
          <div className="text-center text-gray-500">Loading match details...</div>
        )}
        {resultMessage && <div className="mt-6 text-center text-lg font-semibold text-primary">{resultMessage}</div>}
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminEditMatchPage;
