import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { FaArrowLeft } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Country } from '../../../../../types/enums';
import { UUID } from 'crypto';
import { LeagueResponse, LeagueSeasonStatsResponse } from '../../../../../types/apimodels';

interface FormValues {
  country: string;
  leagueId: string;
  leagueSeasonStatsId: string;
  roundNumber: number;
  roundDate: string;
}

const AdminRegisterRoundPage: React.FC = () => {
  const { role } = useJwt();
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();

  const [resultMessage, setResultMessage] = useState<string | null>(null);
  const [leagues, setLeagues] = useState<LeagueResponse[] | null>(null);
  const [selectedLeague, setSelectedLeague] = useState<LeagueResponse | null>(null);

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { registerRoundAsync, getLeagueByCountryAsync } = apiContext;

  const now = new Date();
  const prefilledDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString().slice(0, 16);

  const validationSchema = Yup.object({
    country: Yup.string().required('Required'),
    leagueId: Yup.string().required('Required'),
    leagueSeasonStatsId: Yup.string().required('Required'),
    roundNumber: Yup.number()
      .min(1, 'Must be at least 1')
      .required('Required')
      .test('max-round', 'Round number too large', function (value) {
        if (selectedLeague && value) {
          return value <= selectedLeague.numberOfRounds;
        }
        return true;
      }),
    roundDate: Yup.string().required('Required'),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      country: '',
      leagueId: '',
      leagueSeasonStatsId: '',
      roundNumber: 1,
      roundDate: prefilledDateTime,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const leagueSeasonStatsId = values.leagueSeasonStatsId as UUID;
      const roundNumber = values.roundNumber;
      const roundDate = new Date(values.roundDate);

      const request = {
        RoundNumber: roundNumber,
        LeagueSeasonStatsId: leagueSeasonStatsId,
        Deadline: roundDate,
      };

      const success = await registerRoundAsync(request);
      if (success) {
        setResultMessage('Round registration was successful');
        formik.resetForm();
        setSelectedLeague(null);
        setLeagues(null);
      } else {
        setResultMessage('Round registration failed');
      }
    },
  });

  const { values, handleChange, handleBlur, touched, errors, setFieldValue } = formik;

  useEffect(() => {
    const fetchLeagues = async () => {
      if (values.country) {
        const countryValue = parseInt(values.country, 10) as Country;
        const response = await getLeagueByCountryAsync(countryValue);
        setLeagues(response);
        void setFieldValue('leagueId', '');
        void setFieldValue('leagueSeasonStatsId', '');
        setSelectedLeague(null);
      } else {
        setLeagues(null);
        setSelectedLeague(null);
      }
    };

    (async () => {
      await fetchLeagues();
    })();
  }, [values.country, getLeagueByCountryAsync, setFieldValue]);

  useEffect(() => {
    if (leagues && values.leagueId) {
      const league = leagues.find(l => l.id === values.leagueId);
      setSelectedLeague(league || null);
      // Use void again if needed
      void setFieldValue('leagueSeasonStatsId', '');
    } else {
      setSelectedLeague(null);
    }
  }, [values.leagueId, leagues, setFieldValue]);

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <div className="flex items-center mb-6">
          <button className="text-primary hover:text-primary-dark" onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} />
          </button>
          <h1 className="text-3xl font-bold text-primary text-center flex-grow">Register Round</h1>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg mx-auto space-y-4">
          {/* Country */}
          <div>
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Country <span className="text-red-500">*</span>
            </label>
            <select
              name="country"
              className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
              value={values.country}
              onChange={handleChange}
              onBlur={handleBlur}>
              <option value="" label="Select country" />
              {Object.keys(Country)
                .filter(key => isNaN(Number(key)))
                .map(key => {
                  const value = Country[key as keyof typeof Country] as number;
                  return (
                    <option key={key} value={value.toString()}>
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </option>
                  );
                })}
            </select>
            {touched.country && errors.country && <div className="text-red-500 text-sm mt-1">{errors.country}</div>}
          </div>

          {/* Leagues */}
          {values.country && (
            <>
              {leagues === null ? (
                <p className="text-center text-gray-500">Loading leagues...</p>
              ) : leagues && leagues.length === 0 ? (
                <p className="text-center text-gray-500">No leagues found for this country.</p>
              ) : (
                leagues && (
                  <div>
                    <label htmlFor="leagueId" className="block text-sm font-medium text-gray-700">
                      League <span className="text-red-500">*</span>
                    </label>
                    <select
                      name="leagueId"
                      className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                      value={values.leagueId}
                      onChange={handleChange}
                      onBlur={handleBlur}>
                      <option value="" label="Select league" />
                      {leagues.map(league => (
                        <option key={league.id} value={league.id}>
                          {league.name}
                        </option>
                      ))}
                    </select>
                    {touched.leagueId && errors.leagueId && (
                      <div className="text-red-500 text-sm mt-1">{errors.leagueId}</div>
                    )}
                  </div>
                )
              )}
            </>
          )}

          {/* LeagueSeasonStats */}
          {selectedLeague && (
            <>
              {selectedLeague.leagueSeasonStats.length === 0 ? (
                <p className="text-center text-gray-500">No league season stats available for this league.</p>
              ) : (
                <div>
                  <label htmlFor="leagueSeasonStatsId" className="block text-sm font-medium text-gray-700">
                    League Season Stats <span className="text-red-500">*</span>
                  </label>
                  <select
                    name="leagueSeasonStatsId"
                    className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                    value={values.leagueSeasonStatsId}
                    onChange={handleChange}
                    onBlur={handleBlur}>
                    <option value="" label="Select League Season Stats" />
                    {selectedLeague.leagueSeasonStats.map((stats: LeagueSeasonStatsResponse) => (
                      <option key={stats.id} value={stats.id}>
                        {`Season: ${stats.season}`} {/* Ensure 'season' field exists or adjust accordingly */}
                      </option>
                    ))}
                  </select>
                  {touched.leagueSeasonStatsId && errors.leagueSeasonStatsId && (
                    <div className="text-red-500 text-sm mt-1">{errors.leagueSeasonStatsId}</div>
                  )}
                </div>
              )}
            </>
          )}

          {/* Round Number */}
          {selectedLeague && (
            <div>
              <label htmlFor="roundNumber" className="block text-sm font-medium text-gray-700">
                Round Number <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="roundNumber"
                min={1}
                max={selectedLeague.numberOfRounds}
                placeholder={`Between 1 and ${selectedLeague.numberOfRounds}`}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={values.roundNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.roundNumber && errors.roundNumber && (
                <div className="text-red-500 text-sm mt-1">{errors.roundNumber}</div>
              )}
            </div>
          )}

          {/* Round Date */}
          <div>
            <label htmlFor="roundDate" className="block text-sm font-medium text-gray-700">
              Round Date (UTC)<span className="text-red-500">*</span>
            </label>
            <input
              type="datetime-local"
              name="roundDate"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
              value={values.roundDate}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.roundDate && errors.roundDate && (
              <div className="text-red-500 text-sm mt-1">{errors.roundDate}</div>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            Register Round
          </button>
        </form>

        {/* Result Message */}
        {resultMessage && <div className="mt-6 text-center text-lg font-semibold text-primary">{resultMessage}</div>}
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminRegisterRoundPage;
