import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { AdminGetUserResponse } from '../../../../../types/apimodels';
import { FaArrowLeft } from 'react-icons/fa';

type SortField = 'id' | 'name' | 'userName' | 'emailConfirmed' | 'phoneNumber' | 'role';

const AdminHandleUsersPage: React.FC = () => {
  const { role } = useJwt();
  const navigate = useNavigate();
  const [users, setUsers] = useState<AdminGetUserResponse[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<AdminGetUserResponse[]>([]);
  const [filterRole, setFilterRole] = useState<string>('');
  const [filterEmailConfirmed, setFilterEmailConfirmed] = useState<string>('');
  const [sortField, setSortField] = useState<SortField>('id');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const apiContext = useContext(ApiContext);
  const hasFetchedUsers = useRef(false);

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    } else {
      fetchUsers();
    }
  }, [role, navigate]);

  const fetchUsers = async () => {
    if (apiContext?.adminGetAllUsersAsync && !hasFetchedUsers.current) {
      hasFetchedUsers.current = true;
      const response = await apiContext.adminGetAllUsersAsync();
      setUsers(response);
      setFilteredUsers(response);
    }
  };

  const handleDeleteUser = async (userId: string) => {
    const confirmDelete = window.confirm('Do you really want to delete this user?');
    if (confirmDelete && apiContext?.adminDeleteUserAsync) {
      const success = await apiContext.adminDeleteUserAsync(userId);
      if (success) {
        setUsers(users.filter(user => user.id !== userId));
        setFilteredUsers(filteredUsers.filter(user => user.id !== userId));
      } else {
        alert('Failed to delete user');
      }
    }
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleFilterChange = (filterValue: string) => {
    let filtered = users;

    if (filterValue.startsWith('role:')) {
      const role = filterValue.split(':')[1];
      filtered = filtered.filter(user => user.role === role);
    } else if (filterValue.startsWith('emailConfirmed:')) {
      const emailConfirmed = filterValue.split(':')[1] === 'true';
      filtered = filtered.filter(user => user.emailConfirmed === emailConfirmed);
    }

    setFilteredUsers(filtered);
  };

  const handleSortChange = (sortValue: string) => {
    if (!sortValue) return;

    const [field, direction] = sortValue.split(':') as [SortField, 'asc' | 'desc'];
    setSortField(field);
    setSortDirection(direction);

    const sorted = [...filteredUsers].sort((a, b) => {
      const aField = a[field];
      const bField = b[field];

      if (aField == null || bField == null) {
        return 0; // Handle null values
      }

      if (aField < bField) return direction === 'asc' ? -1 : 1;
      if (aField > bField) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredUsers(sorted);
  };

  useEffect(() => {
    handleFilterChange(filterRole);
  }, [filterRole]);

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <button className="text-primary hover:text-primary-dark mb-4 self-start" onClick={() => navigate(-1)}>
          <FaArrowLeft size={24} />
        </button>
        <h1 className="text-3xl font-bold text-primary text-center mb-6">Manage Users</h1>
        <div className="flex justify-end mb-6">
          <div className="flex space-x-4">
            <select
              className="form-select border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              value={filterRole}
              onChange={e => setFilterRole(e.target.value)}>
              <option value="">Filter by</option>
              <option value="role:admin">Role: Admin</option>
              <option value="role:user">Role: User</option>
              <option value="emailConfirmed:true">Email Confirmed: True</option>
              <option value="emailConfirmed:false">Email Confirmed: False</option>
            </select>
            <select
              className="form-select border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              value={`${sortField}:${sortDirection}`}
              onChange={e => handleSortChange(e.target.value)}>
              <option value="">Sort by</option>
              <option value="name:asc">Name Ascending</option>
              <option value="name:desc">Name Descending</option>
              <option value="userName:asc">Email Ascending</option>
              <option value="userName:desc">Email Descending</option>
              <option value="role:asc">Role Ascending</option>
              <option value="role:desc">Role Descending</option>
            </select>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="py-2 px-4 border-b-2 border-gray-200 cursor-pointer"
                  onClick={() => handleSortChange('id')}>
                  Id
                </th>
                <th
                  className="py-2 px-4 border-b-2 border-gray-200 cursor-pointer"
                  onClick={() => handleSortChange('name')}>
                  Name
                </th>
                <th
                  className="py-2 px-4 border-b-2 border-gray-200 cursor-pointer"
                  onClick={() => handleSortChange('userName')}>
                  Email
                </th>
                <th
                  className="py-2 px-4 border-b-2 border-gray-200 cursor-pointer"
                  onClick={() => handleSortChange('emailConfirmed')}>
                  Email Confirmed
                </th>
                <th
                  className="py-2 px-4 border-b-2 border-gray-200 cursor-pointer"
                  onClick={() => handleSortChange('phoneNumber')}>
                  Phone number
                </th>
                <th
                  className="py-2 px-4 border-b-2 border-gray-200 cursor-pointer"
                  onClick={() => handleSortChange('role')}>
                  Role
                </th>
                <th className="py-2 px-4 border-b-2 border-gray-200">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map(user => (
                <tr
                  key={user.id}
                  onClick={() => handleNavigate(`/admin/users/detailed/${user.id}`)}
                  className="cursor-pointer hover:bg-gray-100">
                  <td className="py-2 px-4 border-b border-gray-200">{user.id}</td>
                  <td className="py-2 px-4 border-b border-gray-200">{user.name}</td>
                  <td className="py-2 px-4 border-b border-gray-200">{user.userName}</td>
                  <td className="py-2 px-4 border-b border-gray-200">{user.emailConfirmed ? 'Yes' : 'No'}</td>
                  <td className="py-2 px-4 border-b border-gray-200">{user.phoneNumber}</td>
                  <td className="py-2 px-4 border-b border-gray-200">{user.role}</td>
                  <td className="py-2 px-4 border-b border-gray-200 flex space-x-2">
                    <button
                      className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600"
                      onClick={e => {
                        e.stopPropagation();
                        handleDeleteUser(user.id);
                      }}>
                      Delete
                    </button>
                    <button
                      className="bg-yellow-500 text-white px-2 py-1 rounded-md hover:bg-yellow-600"
                      onClick={e => {
                        e.stopPropagation();
                        handleNavigate(`/admin/users/update/${user.id}`);
                      }}>
                      Update
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminHandleUsersPage;
