import React, { useContext, useEffect, useState } from 'react';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { Country, Season } from '../../../../../types/enums';
import { LeagueResponse, RoundResponse, TeamSeasonStatsResponse } from '../../../../../types/apimodels';
import { FaArrowLeft, FaEdit, FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { UUID } from 'crypto';

const AdminHandleLeagueSeasonStatsPage: React.FC = () => {
  const { role } = useJwt();
  const navigate = useNavigate();
  const apiContext = useContext(ApiContext);

  const [country, setCountry] = useState<string>('');
  const [leagues, setLeagues] = useState<LeagueResponse[] | null>(null);
  const [selectedLeagueId, setSelectedLeagueId] = useState<string>('');
  const [expandedLss, setExpandedLss] = useState<{ [id: string]: boolean }>({});

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { getLeagueByCountryAsync } = apiContext;

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  const handleCountryChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setCountry(value);
    setLeagues(null);
    setSelectedLeagueId('');
    if (value) {
      const countryValue = parseInt(value, 10) as Country;
      const response = await getLeagueByCountryAsync(countryValue);
      setLeagues(response);
    } else {
      setLeagues(null);
    }
  };

  const handleLeagueChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLeagueId(e.target.value);
  };

  const handleNavigate = (path: string, state?: any) => {
    navigate(path, { state });
  };

  const toggleExpand = (id: UUID) => {
    setExpandedLss(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const selectedLeague = leagues?.find(l => l.id === selectedLeagueId);
  const sortedStats = selectedLeague?.leagueSeasonStats?.slice().sort((a, b) => b.season - a.season) || [];

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <div className="flex items-center mb-6">
          <button className="text-primary hover:text-primary-dark" onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} />
          </button>
          <h1 className="text-3xl font-bold text-primary text-center flex-grow">Manage League Season Stats</h1>
        </div>

        {/* Filter by Country */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Country</label>
          <select
            className="border border-gray-300 rounded-md py-2 px-3 w-full"
            value={country}
            onChange={handleCountryChange}>
            <option value="">Select country</option>
            {Object.keys(Country)
              .filter(key => isNaN(Number(key)))
              .map(key => {
                const value = Country[key as keyof typeof Country] as number;
                return (
                  <option key={key} value={value.toString()}>
                    {key.replace(/([A-Z])/g, ' $1').trim()}
                  </option>
                );
              })}
          </select>
        </div>

        {/* Leagues Dropdown */}
        {country && leagues && (
          <div className="mb-4">
            {leagues.length === 0 ? (
              <p className="text-gray-500">No leagues found for this country.</p>
            ) : (
              <>
                <label className="block text-sm font-medium text-gray-700">League</label>
                <select
                  className="border border-gray-300 rounded-md py-2 px-3 w-full"
                  value={selectedLeagueId}
                  onChange={handleLeagueChange}>
                  <option value="">Select league</option>
                  {leagues.map(l => (
                    <option key={l.id} value={l.id}>
                      {l.name}
                    </option>
                  ))}
                </select>
              </>
            )}
          </div>
        )}

        {/* League Season Stats Table */}
        {selectedLeagueId && sortedStats.length > 0 && (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-lg">
              <thead>
                <tr>
                  <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Season</th>
                  <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Actions</th>
                </tr>
              </thead>
              <tbody>
                {sortedStats.map(lss => {
                  const seasonName = Object.keys(Season)
                    .filter(key => isNaN(Number(key)))
                    .find(key => Season[key as keyof typeof Season] === lss.season);

                  const isExpanded = expandedLss[lss.id] || false;

                  return (
                    <React.Fragment key={lss.id}>
                      <tr className="border-b hover:bg-gray-50">
                        <td className="py-4 px-6 text-sm text-gray-700">
                          <button
                            className="inline-flex items-center"
                            type="button"
                            onClick={() => toggleExpand(lss.id)}>
                            {isExpanded ? <FaChevronDown className="mr-2" /> : <FaChevronRight className="mr-2" />}
                            {seasonName ? seasonName.replace(/([A-Z])/g, ' $1').trim() : lss.season.toString()}
                          </button>
                        </td>
                        <td className="py-4 px-6 text-sm text-gray-700">
                          <button
                            className="flex items-center text-white bg-blue-500 hover:bg-blue-600 px-2 py-1 rounded-md"
                            onClick={e => {
                              e.stopPropagation();
                              // Pass full lss object so we can prefill on the edit page
                              handleNavigate(`/admin/lss/edit/${lss.id}`, { lss });
                            }}>
                            <FaEdit className="mr-1" />
                            Edit
                          </button>
                        </td>
                      </tr>
                      {isExpanded && (
                        <tr>
                          <td colSpan={2} className="py-4 px-6 bg-gray-50">
                            {/* Teams */}
                            <div className="mb-4">
                              <h3 className="font-semibold mb-2">Teams:</h3>
                              {lss.teams.length === 0 ? (
                                <p className="text-sm text-gray-500">No teams</p>
                              ) : (
                                <ul className="list-disc list-inside">
                                  {lss.teams.map((team: TeamSeasonStatsResponse) => (
                                    <li key={team.teamId} className="text-sm text-gray-700">
                                      {team.teamName}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>

                            {/* Rounds */}
                            <div>
                              <h3 className="font-semibold mb-2">Rounds:</h3>
                              {lss.rounds.length === 0 ? (
                                <p className="text-sm text-gray-500">No rounds</p>
                              ) : (
                                <ul className="list-disc list-inside">
                                  {lss.rounds
                                    .slice()
                                    .sort((a: RoundResponse, b: RoundResponse) => a.roundNumber - b.roundNumber)
                                    .map((round: RoundResponse) => (
                                      <li key={round.id} className="text-sm text-gray-700">
                                        Round {round.roundNumber}
                                      </li>
                                    ))}
                                </ul>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
                {sortedStats.length === 0 && (
                  <tr>
                    <td colSpan={2} className="py-4 px-6 text-center text-sm text-gray-500">
                      No league season stats found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {selectedLeagueId && sortedStats.length === 0 && (
          <p className="text-gray-500 mt-4">No league season stats found for this league.</p>
        )}
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminHandleLeagueSeasonStatsPage;
