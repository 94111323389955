import { createContext, FC, ReactNode } from 'react';
import { useJwt } from './JwtProvider';
import { UUID } from 'crypto';
import { useNavigate } from 'react-router-dom';
import {
  AdminGetDetailedUserResponse,
  AdminGetUserResponse,
  AdminRegisterUserData,
  ConfirmEmailRequest,
  FriendProfileUserResponse,
  FriendResponse,
  IOtherUser,
  LeagueResponse,
  LeagueSeasonStatsResponse,
  LoginData,
  MatchResponse,
  MyAccountUserResponse,
  MyFriendRequestsResponse,
  PrivateProfileUserResponse,
  PublicProfileUserResponse,
  PublicSimpleTableResponse,
  RegisterLeagueRequest,
  RegisterLeagueSeasonStatsRequest,
  RegisterMatchRequest,
  RegisterRoundRequest,
  RegisterSimpleTableRequest,
  RegisterTeamRequest,
  RegisterTeamSeasonStatsRequest,
  RegisterUserData,
  ResetPasswordRequest,
  RoundResponse,
  SendFeedbackRequest,
  SimpleTableResponse,
  TeamResponse,
  TeamSeasonStatsResponse,
  UpdatedProfileResponse,
  UpdateLeagueRequest,
  UpdateLeagueSeasonStatsRequest,
  UpdateMatchRequest,
  UpdateRoundRequest,
  UpdateSimpleTableRequest,
  UpdateTeamRequest,
  UpdateTeamSeasonStatsRequest,
  UpdateUserRequest,
} from '../types/apimodels';
import { Country } from '../types/enums';

// Define the type for the context value

interface ApiContextType {
  registrationAsync: (data: RegisterUserData) => Promise<boolean>;
  loginAsync: (data: LoginData) => Promise<boolean>;
  logoutAsync: () => Promise<boolean>;
  adminRegistrationAsync: (data: AdminRegisterUserData) => Promise<boolean>;
  adminGetUserAsync: (userId: string) => Promise<AdminGetDetailedUserResponse | null>;
  adminGetAllUsersAsync: () => Promise<AdminGetUserResponse[]>;
  deleteUserAsync: () => Promise<boolean>;
  adminDeleteUserAsync: (userId: string) => Promise<boolean>;
  adminUpdateUserAsync: (userId: string, request: UpdateUserRequest) => Promise<UpdatedProfileResponse | null>;
  updateUserAsync: (request: UpdateUserRequest) => Promise<UpdatedProfileResponse | null>;
  confirmEmailAsync: (request: ConfirmEmailRequest) => Promise<boolean>;
  GetMyAccountUserAsync: () => Promise<MyAccountUserResponse | null>;
  resetPasswordTokenAsync: (email: string) => Promise<boolean>;
  resetPasswordAsync: (request: ResetPasswordRequest) => Promise<boolean>;
  sendFriendRequestAsync: (friendId: string) => Promise<boolean>;
  acceptFriendRequestAsync: (friendId: string) => Promise<boolean>;
  getMyFriendRequestsAsync: () => Promise<MyFriendRequestsResponse | null>;
  deleteFriendRequestAsync: (requestId: UUID) => Promise<boolean>;
  resendEmailConfirmAsync: () => Promise<boolean>;
  registerSimpleTableAsync: (request: RegisterSimpleTableRequest) => Promise<boolean>;
  getTableAsync: (tableId: UUID) => Promise<SimpleTableResponse | null>;
  getUsersTableAsync: (userHandlerId: UUID) => Promise<SimpleTableResponse[] | null>;
  deleteSimpleTableAsync: (id: UUID) => Promise<boolean>;
  updateSimpleTableAsync: (request: UpdateSimpleTableRequest) => Promise<SimpleTableResponse | null>;
  registerTeamAsync: (request: RegisterTeamRequest) => Promise<boolean>;
  getAllTeamsAsync: () => Promise<TeamResponse[] | null>;
  getTeamByIdAsync: (teamId: UUID) => Promise<TeamResponse | null>;
  getTeamByCountryAsync: (country: Country) => Promise<TeamResponse[] | null>;
  updateTeamAsync: (request: UpdateTeamRequest) => Promise<TeamResponse | null>;
  deleteTeamAsync: (id: UUID) => Promise<boolean>;
  registerLeagueAsync: (request: RegisterLeagueRequest) => Promise<boolean>;
  getAllLeaguesAsync: () => Promise<LeagueResponse[] | null>;
  getLeagueIdAsync: (leagueId: UUID) => Promise<LeagueResponse | null>;
  getLeagueByCountryAsync: (country: Country) => Promise<LeagueResponse[] | null>;
  updateLeagueAsync: (request: UpdateLeagueRequest) => Promise<LeagueResponse | null>;
  deleteLeagueAsync: (id: UUID) => Promise<boolean>;
  registerLssAsync: (request: RegisterLeagueSeasonStatsRequest) => Promise<boolean>;
  getLssByIdAsync: (id: UUID) => Promise<LeagueSeasonStatsResponse | null>;
  updateLssAsync: (request: UpdateLeagueSeasonStatsRequest) => Promise<LeagueSeasonStatsResponse | null>;
  deleteLssAsync: (id: UUID) => Promise<boolean>;
  registerRoundAsync: (request: RegisterRoundRequest) => Promise<boolean>;
  getRoundByIdAsync: (id: UUID) => Promise<RoundResponse | null>;
  updateRoundAsync: (request: UpdateRoundRequest) => Promise<RoundResponse | null>;
  deleteRoundAsync: (id: UUID) => Promise<boolean>;
  getPublicTableAsync: (tableId: UUID) => Promise<PublicSimpleTableResponse | null>;
  updateSimpleTableViewsAsync: (tableId: UUID) => Promise<void>;
  getOfficialSimpleTablesAsync: () => Promise<SimpleTableResponse[] | null>;
  publicRegisterSimpleTableAsync: (request: RegisterSimpleTableRequest, email: string) => Promise<UUID | null>;
  getMyFriendsAsync: () => Promise<FriendResponse[] | null>;
  deleteFriendAsync: (userId: string) => Promise<boolean>;
  sendFeedbackAsync: (request: SendFeedbackRequest) => Promise<Number>;
  getOtherUserAsync: (userId: string) => Promise<IOtherUser | null>;
  registerMatchAsync: (request: RegisterMatchRequest) => Promise<boolean>;
  registerMatchesAsync: (request: RegisterMatchRequest[]) => Promise<boolean>;
  getMatchByIdAsync: (id: UUID) => Promise<MatchResponse | null>;
  updateMatchAsync: (request: UpdateMatchRequest) => Promise<MatchResponse | null>;
  deleteMatchAsync: (id: UUID) => Promise<boolean>;
  registerTssAsync: (request: RegisterTeamSeasonStatsRequest) => Promise<boolean>;
  getTssByIdAsync: (id: UUID) => Promise<TeamSeasonStatsResponse | null>;
  updateTssAsync: (request: UpdateTeamSeasonStatsRequest) => Promise<TeamSeasonStatsResponse | null>;
  deleteTssAsync: (id: UUID) => Promise<boolean>;
}

// Create the context with a default value
export const ApiContext = createContext<ApiContextType | undefined>(undefined);

// Define props for the provider, including children
interface ApiProviderProps {
  children: ReactNode;
}

const ApiProvider: FC<ApiProviderProps> = ({ children }) => {
  const apiUrl = import.meta.env.VITE_API_URL;
  const { jwt, handleLogout, handleLogin } = useJwt();
  const navigate = useNavigate();

  if (!apiUrl) {
    console.log(apiUrl);
    throw new Error('VITE_API_URL environment variable is not defined');
  }

  const loginAsync = async (data: LoginData): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${apiUrl}/account/login`, requestOptions);
      if (response.status === 200) {
        const token: string = await response.text();
        localStorage.setItem('jwt', token);
        handleLogin();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error during login:', error);
      return false;
    }
  };

  const registrationAsync = async (data: RegisterUserData): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/account/register`, requestOptions);
      return response.status === 201;
    } catch (error) {
      console.error('Error during registration:', error);
      return false;
    }
  };

  const adminRegistrationAsync = async (data: AdminRegisterUserData): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      body: JSON.stringify(data),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/account/admin/register`, requestOptions);
      return response.status === 201;
    } catch (error) {
      console.error('Error during registration:', error);
      return false;
    }
  };

  const logoutAsync = async (): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/account/logout`, requestOptions);
      if (response.status === 204) {
        handleLogout();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error during logout:', error);
      return false;
    }
  };

  const adminGetUserAsync = async (userId: string): Promise<AdminGetDetailedUserResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/account/admin/user/${userId}`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error during getting user details:', error);
      return null;
    }
  };

  const adminGetAllUsersAsync = async (): Promise<AdminGetUserResponse[]> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/account/admin/users`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error during getting user details:', error);
      return [];
    }
  };

  const deleteUserAsync = async (): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/account/delete`, requestOptions);
      return response.status === 204;
    } catch (error) {
      console.error('Error during getting user details:', error);
      return false;
    }
  };

  const adminDeleteUserAsync = async (userId: string): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/account/admin/delete/${userId}`, requestOptions);
      return response.status === 204;
    } catch (error) {
      console.error('Error during getting user details:', error);
      return false;
    }
  };

  const adminUpdateUserAsync = async (
    userId: string,
    request: UpdateUserRequest
  ): Promise<UpdatedProfileResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/account/admin/update/${userId}`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error during getting user details:', error);
      return null;
    }
  };

  const updateUserAsync = async (request: UpdateUserRequest): Promise<UpdatedProfileResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/account/update`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error during getting user details:', error);
      return null;
    }
  };

  const confirmEmailAsync = async (request: ConfirmEmailRequest): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/account/confirmemail`, requestOptions);
      return response.status === 200;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const GetMyAccountUserAsync = async (): Promise<MyAccountUserResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/account/myuser`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error during getting user details:', error);
      return null;
    }
  };

  const resetPasswordTokenAsync = async (email: string): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/account/resetpasswordtoken/${email}`, requestOptions);
      return response.status === 204;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const resetPasswordAsync = async (request: ResetPasswordRequest): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/account/resetpassword`, requestOptions);
      return response.status === 200;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const sendFriendRequestAsync = async (friendId: string): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/friends/send/${friendId}`, requestOptions);
      return response.status === 201;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const acceptFriendRequestAsync = async (friendId: string): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/friends/accept/${friendId}`, requestOptions);
      return response.status === 200;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const getMyFriendRequestsAsync = async (): Promise<MyFriendRequestsResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/friends/myrequests`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const deleteFriendRequestAsync = async (requestId: UUID): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/friends/removerequest/${requestId}`, requestOptions);
      return response.status === 204;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const getMyFriendsAsync = async (): Promise<FriendResponse[] | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/friends`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const resendEmailConfirmAsync = async (): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/account/resend/email`, requestOptions);
      return response.status === 200;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const registerSimpleTableAsync = async (request: RegisterSimpleTableRequest): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/table`, requestOptions);
      return response.status === 201;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const getTableAsync = async (tableId: UUID): Promise<SimpleTableResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/table/${tableId}`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const getUsersTableAsync = async (userHandlerId: UUID): Promise<SimpleTableResponse[] | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/table/user/${userHandlerId}`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const deleteSimpleTableAsync = async (id: UUID): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/table/${id}`, requestOptions);
      return response.status === 204;
    } catch (error) {
      console.error('Error during getting user details:', error);
      return false;
    }
  };

  const updateSimpleTableAsync = async (request: UpdateSimpleTableRequest): Promise<SimpleTableResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/table`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error during getting user details:', error);
      return null;
    }
  };

  const registerTeamAsync = async (request: RegisterTeamRequest): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/team`, requestOptions);
      return response.status === 201;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const getAllTeamsAsync = async (): Promise<TeamResponse[] | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/team`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const getTeamByIdAsync = async (teamId: UUID): Promise<TeamResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      console.log(teamId);
      const response = await fetch(`${apiUrl}/team/${teamId}`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const getTeamByCountryAsync = async (country: Country): Promise<TeamResponse[] | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/team/country/${country}`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const updateTeamAsync = async (request: UpdateTeamRequest): Promise<TeamResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/team`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error during getting user details:', error);
      return null;
    }
  };

  const deleteTeamAsync = async (id: UUID): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/team/${id}`, requestOptions);
      return response.status === 204;
    } catch (error) {
      console.error('Error during getting user details:', error);
      return false;
    }
  };

  const registerLeagueAsync = async (request: RegisterLeagueRequest): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/league`, requestOptions);
      return response.status === 201;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const getAllLeaguesAsync = async (): Promise<LeagueResponse[] | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/league`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const getLeagueIdAsync = async (leagueId: UUID): Promise<LeagueResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/league/${leagueId}`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const getLeagueByCountryAsync = async (country: Country): Promise<LeagueResponse[] | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/league/country/${country}`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const updateLeagueAsync = async (request: UpdateLeagueRequest): Promise<LeagueResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/league`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error during getting user details:', error);
      return null;
    }
  };

  const deleteLeagueAsync = async (id: UUID): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/league/${id}`, requestOptions);
      return response.status === 204;
    } catch (error) {
      console.error('Error during getting user details:', error);
      return false;
    }
  };

  const registerLssAsync = async (request: RegisterLeagueSeasonStatsRequest): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/lss`, requestOptions);
      return response.status === 201;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const getLssByIdAsync = async (id: UUID): Promise<LeagueSeasonStatsResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/lss/${id}`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const updateLssAsync = async (request: UpdateLeagueSeasonStatsRequest): Promise<LeagueSeasonStatsResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/lss`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error during getting user details:', error);
      return null;
    }
  };

  const deleteLssAsync = async (id: UUID): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/lss/${id}`, requestOptions);
      return response.status === 204;
    } catch (error) {
      console.error('Error during getting user details:', error);
      return false;
    }
  };

  const registerTssAsync = async (request: RegisterTeamSeasonStatsRequest): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/tss`, requestOptions);
      return response.status === 201;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const getTssByIdAsync = async (id: UUID): Promise<TeamSeasonStatsResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/tss/${id}`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const updateTssAsync = async (request: UpdateTeamSeasonStatsRequest): Promise<TeamSeasonStatsResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/tss`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error during getting user details:', error);
      return null;
    }
  };

  const deleteTssAsync = async (id: UUID): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/tss/${id}`, requestOptions);
      return response.status === 204;
    } catch (error) {
      console.error('Error during getting user details:', error);
      return false;
    }
  };

  const registerRoundAsync = async (request: RegisterRoundRequest): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/round`, requestOptions);
      return response.status === 201;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const getRoundByIdAsync = async (id: UUID): Promise<RoundResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/round/${id}`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const updateRoundAsync = async (request: UpdateRoundRequest): Promise<RoundResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/round`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error during getting user details:', error);
      return null;
    }
  };

  const deleteRoundAsync = async (id: UUID): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/round/${id}`, requestOptions);
      return response.status === 204;
    } catch (error) {
      console.error('Error during getting user details:', error);
      return false;
    }
  };

  const registerMatchAsync = async (request: RegisterMatchRequest): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/match`, requestOptions);
      return response.status === 201;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const registerMatchesAsync = async (request: RegisterMatchRequest[]): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/match/round`, requestOptions);
      return response.status === 201;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const getMatchByIdAsync = async (id: UUID): Promise<MatchResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/match/${id}`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const updateMatchAsync = async (request: UpdateMatchRequest): Promise<MatchResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/match`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error during getting user details:', error);
      return null;
    }
  };

  const deleteMatchAsync = async (id: UUID): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/round/${id}`, requestOptions);
      return response.status === 204;
    } catch (error) {
      console.error('Error during getting user details:', error);
      return false;
    }
  };

  const getPublicTableAsync = async (tableId: UUID): Promise<PublicSimpleTableResponse | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/publictable/${tableId}`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const updateSimpleTableViewsAsync = async (tableId: UUID): Promise<void> => {
    const requestOptions: RequestInit = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      credentials: 'include',
    };

    try {
      await fetch(`${apiUrl}/publictable/${tableId}`, requestOptions);
    } catch (error) {
      console.error('Error during getting user details:', error);
    }
  };

  const getOfficialSimpleTablesAsync = async (): Promise<SimpleTableResponse[] | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/publictable/official`, requestOptions);
      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const publicRegisterSimpleTableAsync = async (
    request: RegisterSimpleTableRequest,
    email: string
  ): Promise<UUID | null> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/publictable/${email}`, requestOptions);
      if (response.status === 201) {
        const guid = await response.text();
        return guid as UUID;
      } else {
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const deleteFriendAsync = async (userId: string): Promise<boolean> => {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/friends/${userId}`, requestOptions);
      return response.status === 204;
    } catch (error) {
      console.error('Error during getting user details:', error);
      return false;
    }
  };

  const sendFeedbackAsync = async (request: SendFeedbackRequest): Promise<Number> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(request),
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/Email/feedback`, requestOptions);
      return response.status;
    } catch (error) {
      console.error('An error occurred:', error);
      return 500;
    }
  };

  const getOtherUserAsync = async (userId: string): Promise<IOtherUser | null> => {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      credentials: 'include',
    };

    try {
      const response = await fetch(`${apiUrl}/account/user/${userId}`, requestOptions);

      if (response.status === 200) {
        const data: IOtherUser = await response.json();

        switch (data.type) {
          case 'FriendProfileUserResponse':
            return data as FriendProfileUserResponse;
          case 'PublicProfileUserResponse':
            return data as PublicProfileUserResponse;
          case 'PrivateProfileUserResponse':
            return data as PrivateProfileUserResponse;
          default:
            console.error('Unknown response type:', data.type);
            return data;
        }
      } else if (response.status === 409) {
        navigate('/my-account/profile');
        return null;
      } else {
        console.error('Failed to fetch user data:', response.statusText);
        return null;
      }
    } catch (error) {
      console.error('Error during fetching user data:', error);
      return null;
    }
  };

  const value = {
    registrationAsync,
    loginAsync,
    logoutAsync,
    adminRegistrationAsync,
    adminGetUserAsync,
    adminGetAllUsersAsync,
    deleteUserAsync,
    adminDeleteUserAsync,
    adminUpdateUserAsync,
    updateUserAsync,
    confirmEmailAsync,
    GetMyAccountUserAsync,
    resetPasswordTokenAsync,
    resetPasswordAsync,
    sendFriendRequestAsync,
    acceptFriendRequestAsync,
    getMyFriendRequestsAsync,
    deleteFriendRequestAsync,
    getMyFriendsAsync,
    resendEmailConfirmAsync,
    registerSimpleTableAsync,
    getTableAsync,
    getUsersTableAsync,
    deleteSimpleTableAsync,
    updateSimpleTableAsync,
    registerTeamAsync,
    getAllTeamsAsync,
    getTeamByIdAsync,
    getTeamByCountryAsync,
    updateTeamAsync,
    deleteTeamAsync,
    registerLeagueAsync,
    getAllLeaguesAsync,
    getLeagueIdAsync,
    getLeagueByCountryAsync,
    updateLeagueAsync,
    deleteLeagueAsync,
    registerLssAsync,
    getLssByIdAsync,
    updateLssAsync,
    deleteLssAsync,
    registerRoundAsync,
    getRoundByIdAsync,
    updateRoundAsync,
    deleteRoundAsync,
    getPublicTableAsync,
    updateSimpleTableViewsAsync,
    getOfficialSimpleTablesAsync,
    publicRegisterSimpleTableAsync,
    deleteFriendAsync,
    sendFeedbackAsync,
    getOtherUserAsync,
    registerMatchAsync,
    registerMatchesAsync,
    getMatchByIdAsync,
    updateMatchAsync,
    deleteMatchAsync,
    registerTssAsync,
    getTssByIdAsync,
    updateTssAsync,
    deleteTssAsync,
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export default ApiProvider;
