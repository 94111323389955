import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { FaArrowLeft } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Country, Season } from '../../../../../types/enums';
import {
  LeagueResponse,
  LeagueSeasonStatsResponse,
  TeamResponse,
  TeamSeasonStatsResponse,
} from '../../../../../types/apimodels';
import { UUID } from 'crypto';

interface FormValues {
  country: string;
  leagueId: string;
  leagueSeasonStatsId: string;
  teamId: string;
}

const AdminRegisterTeamSeasonStatsPage: React.FC = () => {
  const { role } = useJwt();
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();

  const [resultMessage, setResultMessage] = useState<string | null>(null);
  const [leagues, setLeagues] = useState<LeagueResponse[] | null>(null);
  const [selectedLeague, setSelectedLeague] = useState<LeagueResponse | null>(null);
  const [selectedLSS, setSelectedLSS] = useState<LeagueSeasonStatsResponse | null>(null);
  const [allTeams, setAllTeams] = useState<TeamResponse[] | null>(null); // all teams from country
  const [availableTeams, setAvailableTeams] = useState<TeamResponse[] | null>(null); // filtered teams not in LSS

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { registerTssAsync, getTeamByCountryAsync, getLeagueByCountryAsync } = apiContext;

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  const validationSchema = Yup.object({
    country: Yup.string().required('Required'),
    leagueId: Yup.string().required('Required'),
    leagueSeasonStatsId: Yup.string().required('Required'),
    teamId: Yup.string().required('Required'),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      country: '',
      leagueId: '',
      leagueSeasonStatsId: '',
      teamId: '',
    },
    validationSchema,
    onSubmit: async values => {
      if (!selectedLSS) {
        setResultMessage('No League Season Stats selected');
        return;
      }

      const teamId = values.teamId as UUID;
      const leagueSeasonStatsId = values.leagueSeasonStatsId as UUID;
      const season = selectedLSS.season; // from LSS directly

      const request = {
        teamId,
        season,
        leagueSeasonStatsId,
      };

      const success = await registerTssAsync(request);
      if (success) {
        setResultMessage('Team Season Stats registered successfully');
        formik.resetForm();
        setLeagues(null);
        setSelectedLeague(null);
        setSelectedLSS(null);
        setAllTeams(null);
        setAvailableTeams(null);
      } else {
        setResultMessage('Failed to register Team Season Stats');
      }
    },
  });

  // Once country changes, fetch leagues
  useEffect(() => {
    const fetchLeagues = async () => {
      if (formik.values.country) {
        const countryValue = parseInt(formik.values.country, 10) as Country;
        const response = await getLeagueByCountryAsync(countryValue);
        setLeagues(response);
        formik.setFieldValue('leagueId', '');
        formik.setFieldValue('leagueSeasonStatsId', '');
        formik.setFieldValue('teamId', '');
        setSelectedLeague(null);
        setSelectedLSS(null);
        setAllTeams(null);
        setAvailableTeams(null);

        // Also fetch all teams for that country
        const teams = await getTeamByCountryAsync(countryValue);
        setAllTeams(teams);
      } else {
        setLeagues(null);
        setSelectedLeague(null);
        setSelectedLSS(null);
        setAllTeams(null);
        setAvailableTeams(null);
        formik.setFieldValue('leagueId', '');
        formik.setFieldValue('leagueSeasonStatsId', '');
        formik.setFieldValue('teamId', '');
      }
    };
    void fetchLeagues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country]);

  // Once leagueId changes, set selectedLeague
  useEffect(() => {
    if (leagues && formik.values.leagueId) {
      const league = leagues.find(l => l.id === formik.values.leagueId) || null;
      setSelectedLeague(league);
      setSelectedLSS(null);
      setAvailableTeams(null);
      formik.setFieldValue('leagueSeasonStatsId', '');
      formik.setFieldValue('teamId', '');
    } else {
      setSelectedLeague(null);
      setSelectedLSS(null);
      setAvailableTeams(null);
      formik.setFieldValue('leagueSeasonStatsId', '');
      formik.setFieldValue('teamId', '');
    }
  }, [formik.values.leagueId, leagues]);

  // Once leagueSeasonStatsId changes, set selectedLSS and filter teams
  useEffect(() => {
    if (selectedLeague && formik.values.leagueSeasonStatsId) {
      const lss = selectedLeague.leagueSeasonStats.find(s => s.id === formik.values.leagueSeasonStatsId) || null;
      setSelectedLSS(lss);
      formik.setFieldValue('teamId', '');

      if (lss && allTeams) {
        // filter out teams already in LSS
        const lssTeamIds = lss.teams.map((t: TeamSeasonStatsResponse) => t.teamId);
        const filtered = allTeams.filter(t => !lssTeamIds.includes(t.id));
        setAvailableTeams(filtered);
      } else {
        setAvailableTeams(null);
      }
    } else {
      setSelectedLSS(null);
      setAvailableTeams(null);
      formik.setFieldValue('teamId', '');
    }
  }, [formik.values.leagueSeasonStatsId, selectedLeague, allTeams]);

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 pt-20">
        <div className="flex items-center mb-6">
          <button className="text-primary hover:text-primary-dark" onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} />
          </button>
          <h1 className="text-3xl font-bold text-primary text-center flex-grow">Register Team Season Stats</h1>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg mx-auto space-y-4">
          {/* Country */}
          <div>
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Country <span className="text-red-500">*</span>
            </label>
            <select
              name="country"
              className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="" label="Select country" />
              {Object.keys(Country)
                .filter(key => isNaN(Number(key)))
                .map(key => {
                  const value = Country[key as keyof typeof Country] as number;
                  return (
                    <option key={key} value={value.toString()}>
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </option>
                  );
                })}
            </select>
            {formik.touched.country && formik.errors.country && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.country}</div>
            )}
          </div>

          {/* League */}
          {formik.values.country && leagues && (
            <div>
              <label htmlFor="leagueId" className="block text-sm font-medium text-gray-700">
                League <span className="text-red-500">*</span>
              </label>
              {leagues.length === 0 ? (
                <p className="text-gray-500 text-sm mt-2">No leagues found for this country.</p>
              ) : (
                <select
                  name="leagueId"
                  className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                  value={formik.values.leagueId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}>
                  <option value="" label="Select league" />
                  {leagues.map(l => (
                    <option key={l.id} value={l.id}>
                      {l.name}
                    </option>
                  ))}
                </select>
              )}
              {formik.touched.leagueId && formik.errors.leagueId && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.leagueId}</div>
              )}
            </div>
          )}

          {/* League Season Stats */}
          {selectedLeague && (
            <div>
              <label htmlFor="leagueSeasonStatsId" className="block text-sm font-medium text-gray-700">
                League Season Stats <span className="text-red-500">*</span>
              </label>
              {selectedLeague.leagueSeasonStats.length === 0 ? (
                <p className="text-gray-500 text-sm mt-2">No league season stats available for this league.</p>
              ) : (
                <select
                  name="leagueSeasonStatsId"
                  className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                  value={formik.values.leagueSeasonStatsId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}>
                  <option value="" label="Select league season stats" />
                  {/* Sort LSS by season descending */}
                  {[...selectedLeague.leagueSeasonStats]
                    .sort((a, b) => b.season - a.season)
                    .map(stats => {
                      const seasonName = Object.keys(Season)
                        .filter(key => isNaN(Number(key)))
                        .find(key => Season[key as keyof typeof Season] === stats.season);
                      return (
                        <option key={stats.id} value={stats.id}>
                          {seasonName ? seasonName.replace(/([A-Z])/g, ' $1').trim() : stats.season.toString()}
                        </option>
                      );
                    })}
                </select>
              )}
              {formik.touched.leagueSeasonStatsId && formik.errors.leagueSeasonStatsId && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.leagueSeasonStatsId}</div>
              )}
            </div>
          )}

          {/* Display chosen season as read-only if selectedLSS is chosen */}
          {selectedLSS && (
            <div>
              <label className="block text-sm font-medium text-gray-700">Season</label>
              <div className="mt-1 p-2 border border-gray-300 bg-gray-50 rounded-md">
                {(() => {
                  const seasonName = Object.keys(Season)
                    .filter(key => isNaN(Number(key)))
                    .find(key => Season[key as keyof typeof Season] === selectedLSS.season);
                  return seasonName ? seasonName.replace(/([A-Z])/g, ' $1').trim() : selectedLSS.season.toString();
                })()}
              </div>
            </div>
          )}

          {/* Teams */}
          {selectedLSS && availableTeams && (
            <div>
              <label htmlFor="teamId" className="block text-sm font-medium text-gray-700">
                Team <span className="text-red-500">*</span>
              </label>
              {availableTeams.length === 0 ? (
                <p className="text-gray-500 text-sm mt-2">
                  No available teams to add for this League Season Stats (all teams might already be registered).
                </p>
              ) : (
                <select
                  name="teamId"
                  className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                  value={formik.values.teamId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}>
                  <option value="" label="Select team" />
                  {availableTeams.map(team => (
                    <option key={team.id} value={team.id}>
                      {team.name}
                    </option>
                  ))}
                </select>
              )}
              {formik.touched.teamId && formik.errors.teamId && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.teamId}</div>
              )}
            </div>
          )}

          <button
            type="submit"
            className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            disabled={!selectedLSS || !availableTeams || availableTeams.length === 0}>
            Register Team Season Stats
          </button>
        </form>

        {/* Result Message */}
        {resultMessage && <div className="mt-6 text-center text-lg font-semibold text-primary">{resultMessage}</div>}
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminRegisterTeamSeasonStatsPage;
